import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { ReactComponent as OverviewIcon } from "../Icons/Overview.svg";
import { ReactComponent as ManagementIcon } from "../Icons/Management.svg";
import { ReactComponent as FacilitiesIcon } from "../Icons/Facilities.svg";
import { ReactComponent as FaultReportIcon } from "../Icons/FaultReport.svg";
import { ReactComponent as ServiceRequestIcon } from "../Icons/ServiceRequest.svg";
import { ReactComponent as ParcelCollectionIcon } from "../Icons/ParcelCollection.svg";
import { ReactComponent as ContactBookIcon } from "../Icons/ContactBook.svg";
import { ReactComponent as AnnouncementIcon } from "../Icons/Announcement.svg";
import { ReactComponent as PropertyIcon } from "../Icons/Property.svg";
import { ReactComponent as MarketingIcon } from "../Icons/Marketing.svg";

function SideBarRouteOptions({
  depthStep = 10,
  depth = 1,
  item,
  open = true,
  ...rest
}) {
  const [collapsed, setCollapsed] = React.useState(true);
  const navigate = useNavigate();
  const {
    name,
    key: uri_link,
    sub_menu: items,
    icon_path,
    onClick: onClickProp,
  } = item;
  const isActive =
    uri_link === "service-request"
      ? false
      : window.location.pathname.includes(`/${uri_link}`)
      ? true
      : false;
  const toggleCollapse = () => {
    setCollapsed((prevValue) => !prevValue);
  };

  const onClick = (e, link) => {
    if (items?.length >= 1) {
      toggleCollapse();
    } else {
      navigate(`/${link}`);
    }
  };

  let expandIcon;

  if (items?.length >= 1 && items.length) {
    expandIcon = !collapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />;
  }

  console.log("item", items);

  return (
    <>
      <NavLink
        key={item.id}
        style={{
          color: "#6C737F",
          textDecoration: "none",
          display: "block",
          margin: open ? "5px 10px" : 0,
          width: "auto",
          borderRadius: "10px",
          paddingTop: item?.id === 29 ? "32px" : "initial",
        }}
        onClick={(e) => {
          if (items?.length >= 1) onClick(e, uri_link);
        }}
        to={items?.length >= 1 ? window.location.pathname : `/${uri_link}`}
      >
        <ListItemButton
          sx={{
            padding: open
              ? `0px 0px 0px ${depth * depthStep}px`
              : "0px 19px 0px 19px",
            minHeight: 48,
            display: "flex",
            width: "100%",
            justifyContent: open ? "initial" : "center",
            "&:hover": {
              backgroundColor: isActive ? "none" : "#E5E7EB",
              borderRadius: "6px",
              "& #hover-text": {
                fontWeight: 600,
                fontSize: "14px",
                color: "#384250",
              },
            },
          }}
          style={{
            backgroundColor: isActive ? "#C4D6E0" : "",
            borderRadius: isActive ? "6px" : "",
          }}
        >
          <Tooltip title={open ? "" : name} placement="right-start">
            <ListItemIcon
              sx={{
                "& svg path": { stroke: isActive ? "#0E5E84" : "#6C737F" },
                minWidth: 0,
                marginRight: open ? 0 : "auto",
                justifyContent: "center",
                height: "35px",
                width: "35px",
                display: "flex",
                alignItems: "center",
                borderRadius: "4px",
              }}
            >
              {icon_path ? (
                <img src={`${process.env.REACT_APP_FILE_PATH}${icon_path}`} />
              ) : (
                ""
              )}
            </ListItemIcon>
          </Tooltip>
          <ListItemText
            primary={name}
            id="hover-text"
            sx={{
              ".MuiTypography-root": {
                fontSize: "14px",
                fontWeight: isActive ? 600 : 500,
                letterSpacing: "-0.25 px",
              },
              color: isActive ? "#0E5E84" : "#6C737F",
              opacity: open ? 1 : 0,
              marginLeft: "10px",
              textTransform: "capitalize",
            }}
          />

          {open ? expandIcon : null}
        </ListItemButton>
      </NavLink>
      <Collapse in={!collapsed} timeout="auto" unmountOnExit>
        {items?.length >= 1 ? (
          <List disablePadding dense>
            {items.map((subItem, index) => (
              <React.Fragment key={`${subItem.name}${index}`}>
                <SideBarRouteOptions
                  item={subItem}
                  open={open}
                  depth={depth + 1}
                  depthStep={depthStep}
                />
              </React.Fragment>
            ))}
          </List>
        ) : null}
      </Collapse>
    </>
  );
}

export default function SideBar({ items }) {
  console.log("items:", items);
  return items.map((sidebarItem, index) => (
    <div
      key={`${sidebarItem.name}${index}`}
      style={{ color: "#8e8e8e", textDecoration: "none" }}
    >
      <SideBarRouteOptions item={sidebarItem} />
    </div>
  ));
}

const NavBarIcons = {
  Overview: <OverviewIcon />,
  Management: <ManagementIcon />,
  Facilities: <FacilitiesIcon />,
  "Fault Reports": <FaultReportIcon />,
  "Service Request": <ServiceRequestIcon />,
  "Parcel Collection": <ParcelCollectionIcon />,
  "Contact Book": <ContactBookIcon />,
  Marketing: <MarketingIcon />,
  Announcement: <AnnouncementIcon />,
  Property: <PropertyIcon />,
};
