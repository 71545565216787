import React, { useEffect, useState } from "react";
import { ReactComponent as BackArrow } from "../../../Icons/BorderBackArrow.svg";
import { ReactComponent as AddBuildingIcon } from "../../../Icons/AddBuilding.svg";
import { ReactComponent as Close } from "../../../Icons/BorderAlertClose.svg";
import { ReactComponent as UploadIcon } from "../../../Icons/UploadIcon.svg";
import { ReactComponent as RefreshIcon } from "../../../Icons/Refresh.svg";
import { ReactComponent as DownloadIcon } from "../../../Icons/DownloadIcon.svg";
import { ReactComponent as XlsDownloadIcon } from "../../../Icons/XlsDownloadIcon.svg";
import {
  ErrorTypography,
  PrimaryTypography,
  SecondaryTypography,
  StyledSecondaryTypography,
  StyledTypography,
} from "../../../Theme";
import {
  Box,
  Button,
  TextField,
  Typography,
  Modal,
  Grid,
  Stack,
  Chip,
  Container,
} from "@mui/material";
import {
  setActiveStep,
  setNestedStep,
} from "../../../Redux/Feature/PropertyDetails/propertyDetailsSlice";
import { useDispatch } from "react-redux";
import { ReactComponent as EditBuilding } from "../../../Icons/EditBuilding.svg";
import { ReactComponent as ExcelIcon } from "../../../Icons/Excel.svg";
import moment from "moment";
import Dropzone from "react-dropzone";
import axiosInstance from "../../../Redux/AxiosInstance";
import { useSelector } from "react-redux";
import { showToast } from "../../../CommonComponents/Toaster";
import { saveAs } from "file-saver";
import { PostLoaderModal } from "../../../CommonComponents/modal";

const AddBuilding = ({ setDisplayScreen }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const [declineModelOpen, setDeclineModelOpen] = useState(false);
  const [buildingCount, setBuildingCount] = useState(null);
  const [inputError, setInputError] = useState("");
  const [buildingData, setBuildingData] = useState([]);
  const [editModalName, setEditModalName] = useState(false);
  const [editData, setEditData] = useState({ id: "", building: "" });
  const [loader, setLoader] = useState(false);
  const [xlsError, setXlsError] = useState("");
  const [errId, setErrorId] = useState(null);
  const [levelTrigger, setLevelTrigger] = useState(false);
  const [previewData, setPreviewData] = useState([]);

  const addMoreBuildings = () => {
    setDeclineModelOpen(true);
  };

  const getBuilding = () => {
    setLoader(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/building?pagination=false`
      )
      .then((res) => {
        setBuildingData(res?.data?.data?.property_buildings);
        setLoader(false);
      })
      .catch((err) => {
        console.error(err);
        setLoader(false);
        showToast("Something went wrong! Please try again later.", "error");
      });
  };

  useEffect(() => {
    getBuilding();
  }, []);

  const handleCreateBuilding = () => {
    setLoader(true);
    const formatData = new FormData();
    formatData.append("count", buildingCount);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/building`,
        formatData
      )
      .then((res) => {
        setBuildingData(res?.data?.data?.property_building);
        setShowModal(false);
        setLoader(false);
      })
      .catch((err) => {
        setShowModal(false);
        setLoader(false);
        showToast("Something went wrong !. Please try again", "error");
      });
  };

  const handleBuildingContinue = () => {
    if (buildingCount && /^[0-9]+$/.test(buildingCount)) {
      setDeclineModelOpen(false);
      setShowModal(true);
    } else {
      setInputError("Please enter a valid number");
    }
  };

  const backStep = () => {
    dispatch(setActiveStep(0));
  };

  const handleEmptyReplace = (id) => {
    setErrorId(null);
    setXlsError("");
    const updatedBuildingData = buildingData.map((building) => {
      if (building.id === id) {
        return {
          ...building,
          files: false,
        };
      }
      return building;
    });
    setBuildingData(updatedBuildingData);
  };

  const handleGoBack = () => {
    setShowModal(false);
    setDeclineModelOpen(true);
  };
  const allObjectsContainImportFilePath = () => {
    return buildingData.every((building) => building.import_file_path);
  };

  const handleContinue = () => {
    if (allObjectsContainImportFilePath()) {
      setDisplayScreen("addFloor");
      dispatch(setNestedStep(1));
    } else {
      showToast("Please import files for all buildings", "error");
    }
  };

  const onDrop = (id, acceptedFiles) => {
    const buildingName = buildingData?.find((itm) => itm?.id === id);
    const updatedBuildingData = buildingData.map((building) => {
      if (building.id === id) {
        return {
          ...building,
          files: acceptedFiles,
        };
      }
      return building;
    });
    setBuildingData(updatedBuildingData);
    uploadFile(id, acceptedFiles, buildingName?.building_name);
  };

  const uploadFile = (id, files, name) => {
    setLoader(true);
    const formData = new FormData();
    formData.append("building_array_list[0][building_name]", name);
    formData.append("building_array_list[0][id]", id);
    formData.append("building_array_list[0][file]", files[0]);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}property/${propertyId}/building/import`,
        formData
      )
      .then((res) => {
        getBuilding();
        setLoader(false);
        showToast("XLS Template upload successfully");
      })
      .catch((err) => {
        setLoader(false);
        setErrorId(id);
        const importErrors = err?.response?.data?.data?.import_validation_error;
        if (importErrors) {
          const errorMessages = Object.keys(importErrors).map((row, index) => {
            const rowData = importErrors[row];
            const formattedMessage = `${row}: ${rowData.join(" , ")}`;
            const finalData = formattedMessage
              ? formattedMessage.replace(/_/g, " ").charAt(0).toUpperCase() +
                formattedMessage.replace(/_/g, " ").slice(1)
              : "";
            return finalData;
          });
          setXlsError(errorMessages.join("\n"));
        } else {
          setXlsError("An error occurred during file upload.");
        }
      });
  };

  const downloadXlsTemplate = () => {
    const fileUrl = `${process.env.REACT_APP_FILE_PATH}sample/SampleBuildingImport.xlsx`;
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "SampleBuildingImport.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleEditBuildingName = (name, id) => {
    setEditData({ id: id, building: name });
    setEditModalName(true);
  };

  const handleEditConfirm = () => {
    if (!editData.building.trim()) {
      setInputError("Please Enter the building name");
    } else {
      setLoader(true);
      const formData = new FormData();
      formData.append(
        "building_array_list[0][building_name]",
        editData.building
      );
      formData.append("building_array_list[0][id]", editData.id);

      axiosInstance
        .post(
          `${process.env.REACT_APP_API_URL}property/${propertyId}/building/import`,
          formData
        )
        .then((res) => {
          getBuilding();
          setLoader(false);
          setEditModalName(false);
        })
        .catch((err) => {
          const validationError =
            err.response?.data?.data?.validation_error[
              "building_array_list.0.building_name"
            ];
          setLoader(false);
          if (validationError) {
            setInputError(validationError);
          } else {
            showToast("Something went wrong! Please try again later.", "error");
            setEditModalName(false);
          }
        });
    }
  };

  const handlePreviewXls = async (id) => {
    const buildingName = buildingData?.find((itm) => itm?.id === id);
    setPreviewData(buildingName);
    levelopen();
  };

  const levelopen = () => {
    setLevelTrigger(true);
  };

  const handleExistsEmpty = (id) => {
    const updatedBuildingData = buildingData.map((building) => {
      if (building.id === id) {
        return {
          ...building,
          import_file_path: false,
        };
      }
      return building;
    });
    setBuildingData(updatedBuildingData);
  };

  return (
    <>
      {levelTrigger && (
        <Modal open={true}>
          <Container
            sx={{
              // position: "absolute",
              border: "none",
              width: "100%",
              // height: "90%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                // width: "100%",
                borderRadius: "8px",
              }}
            >
              <Box
                sx={{
                  padding: "16px 24px 16px 24px",
                  borderBottom: "1px solid #F3F4F6",
                  borderRadius: "8px 8px 0px 0px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <PrimaryTypography>
                  {previewData?.building_name}
                </PrimaryTypography>
                <Close
                  style={{ cursor: "pointer" }}
                  onClick={() => setLevelTrigger(false)}
                />
              </Box>

              <Box sx={{ padding: "30px 30px 30px 30px" }}>
                {previewData?.level?.map((data) => (
                  <Grid
                    container
                    sx={{
                      borderBottom: "1px solid #E5E7EB",
                      mt: 1,
                      py: 1,
                    }}
                  >
                    <Grid item xs={1}>
                      <SecondaryTypography>{data?.level}</SecondaryTypography>
                    </Grid>
                    <Grid item xs={11} sx={{ display: "flex" }}>
                      <StyledSecondaryTypography>
                        {" "}
                        {data?.area_type_id === 1
                          ? "Residential"
                          : data?.area_type_id === 2
                          ? "Non-residential"
                          : data?.area_type_id === 3
                          ? "Mixed"
                          : ""}{" "}
                        | {data?.total_unit} Units
                      </StyledSecondaryTypography>
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={11} sx={{ my: 1 }}>
                      {data?.building_unit?.map((dataitm) => {
                        return (
                          <Chip
                            sx={{
                              borderRadius: "6px",
                              border: "1px solid #D0D5DD",
                              fontSize: "14px",
                              mr: 2,
                            }}
                            label={dataitm.unit_no}
                            variant="outlined"
                          ></Chip>
                        );
                      })}
                    </Grid>
                  </Grid>
                ))}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    borderRadius: "0px 0px 8px 8px",
                    backgroundColor: "#FFFFFF",
                    paddingTop: "24px",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    onClick={() => setLevelTrigger(false)}
                    style={{
                      borderRadius: "8px",
                      padding: "10px 16px 10px 16px",
                      fontWeight: 600,
                      fontSize: "14px",
                      color: "#FFFFFF",
                      cursor: "pointer",
                      border: "none",
                      backgroundColor: "#0E5E84",
                    }}
                  >
                    Close
                  </Button>
                </Box>
              </Box>
            </Box>
          </Container>
        </Modal>
      )}
      {loader && <PostLoaderModal />}
      <BackArrow
        onClick={backStep}
        style={{ marginBottom: "16px", cursor: "pointer" }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <PrimaryTypography>Property</PrimaryTypography>
          <StyledSecondaryTypography>
            Setup of building structure, including floors and units.
          </StyledSecondaryTypography>
        </Box>
        {buildingData?.length > 0 && (
          <Button
            sx={{
              fontWeight: "600",
              width: "fit-content",
              color: "#0E5E84",
              backgroundColor: "#FFF",
              paddingRight: "20px !important",
              mr: 3,
              border: "1px solid #D2D6DB",
            }}
            variant="outlined"
            onClick={downloadXlsTemplate}
          >
            <DownloadIcon width={40} />
            Download XLS Template
            <XlsDownloadIcon style={{ marginLeft: "0.5rem" }} />
          </Button>
        )}
      </Box>

      {buildingData?.length > 0 ? (
        <>
          {buildingData?.map((value, index) => (
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "8px 8px 8px 8px ",
                mt: "20px",
              }}
            >
              <Box
                sx={{
                  p: 2,
                  borderRadius: "8px 8px 0px 0px",
                  borderBottom: "1px solid #E5E7EB",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderTop: "1px solid #E5E7EB",
                }}
              >
                <EditBuilding
                  style={{ cursor: "pointer", marginRight: "10px" }}
                  onClick={() =>
                    handleEditBuildingName(value?.building_name, value?.id)
                  }
                />
                <SecondaryTypography>
                  {" "}
                  {value?.building_name}{" "}
                </SecondaryTypography>
              </Box>
              {value?.files ? (
                <Box sx={{ padding: "30px 16px 30px 16px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: "1px solid #EAECF0",
                      borderRadius: "12px",
                      p: 2,
                      mt: 2,
                    }}
                  >
                    <Box>
                      <Stack direction="row">
                        <ExcelIcon style={{ marginRight: "12px" }} />
                        <Stack direction="column">
                          <StyledSecondaryTypography>
                            {value?.files[0]?.name}
                          </StyledSecondaryTypography>
                          <StyledSecondaryTypography>
                            {(value?.files[0]?.size / 1048576)?.toFixed(2)} MB
                          </StyledSecondaryTypography>
                        </Stack>
                      </Stack>
                    </Box>
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        onDrop(value.id, acceptedFiles)
                      }
                      accept=".xls, .xlsx"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps()}
                          style={{ display: "inline-block" }}
                        >
                          <input {...getInputProps()} />
                          <Button
                            sx={{
                              borderRadius: "8px",
                              border: "1px solid #D2D6DB",
                              padding: "10px 16px",
                              fontWeight: 600,
                              fontSize: "14px",
                              color: "#0E5E84",
                              marginRight: "1rem",
                            }}
                          >
                            Replace
                          </Button>
                        </div>
                      )}
                    </Dropzone>
                  </Box>
                  {value?.id === errId && (
                    <>
                      {xlsError.split("\n").map((errorMessage, index) => (
                        <ErrorTypography
                          style={{ marginTop: "1rem" }}
                          key={index}
                        >
                          {errorMessage}
                        </ErrorTypography>
                      ))}
                    </>
                  )}
                </Box>
              ) : value?.import_file_path ? (
                <Box
                  sx={{
                    padding: "30px 16px 30px 16px",
                    borderRadius: "0px 0px 8px 8px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: "1px solid #EAECF0",
                      borderRadius: "12px",
                      p: 2,
                      mt: 2,
                    }}
                  >
                    <Box>
                      <Stack direction="row">
                        <ExcelIcon style={{ marginRight: "12px" }} />
                        <Stack direction="column">
                          <StyledSecondaryTypography>
                            {value?.file?.name}
                          </StyledSecondaryTypography>
                          <StyledSecondaryTypography>
                            {value?.file?.size?.toFixed(2) + "KB"}
                          </StyledSecondaryTypography>
                        </Stack>
                      </Stack>
                    </Box>
                    <Box>
                      <Dropzone
                        onDrop={(acceptedFiles) =>
                          onDrop(value.id, acceptedFiles)
                        }
                        accept=".xls, .xlsx"
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps()}
                            style={{ display: "inline-block" }}
                          >
                            <input {...getInputProps()} />
                            <Button
                              sx={{
                                borderRadius: "8px",
                                border: "1px solid #D2D6DB",
                                padding: "10px 16px",
                                fontWeight: 600,
                                fontSize: "14px",
                                color: "#0E5E84",
                                marginRight: "1rem",
                              }}
                            >
                              Replace
                            </Button>
                          </div>
                        )}
                      </Dropzone>
                      <Button
                        sx={{
                          backgroundColor: "#E7EFF3",
                          color: "#0E5E84",
                          "&:hover": {
                            backgroundColor: "#CFDFE6",
                          },
                        }}
                        onClick={() => handlePreviewXls(value?.id)}
                      >
                        Preview
                      </Button>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Grid
                  container
                  xs={12}
                  gap="2.8%"
                  sx={{
                    backgroundColor: "white",
                    padding: "30px 16px 30px 16px",
                    borderRadius: "0px 0px 8px 8px",
                  }}
                >
                  <Dropzone
                    onDrop={(acceptedFiles) => onDrop(value.id, acceptedFiles)}
                    accept=".xls, .xlsx"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <Grid {...getRootProps()} xs={12}>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          sx={{
                            padding: "24px 16px 24px 16px",
                            border: "1px solid #EAECF0",
                            borderRadius: "12px",
                            cursor: "pointer",
                          }}
                        >
                          <Stack
                            flex={1}
                            direction="column"
                            alignItems="center"
                            textAlign="center"
                          >
                            <UploadIcon />
                            <input {...getInputProps()} hidden />
                            <Stack my="5px" direction="row">
                              <Typography
                                marginRight="5px"
                                sx={{
                                  fontSize: "14px !important",
                                  fontWeight: "600 !important",
                                  color: "#0E4965 !important",
                                }}
                              >
                                Click to upload
                              </Typography>
                              <StyledSecondaryTypography
                                sx={{ color: "#4D5761" }}
                              >
                                or drag and drop
                              </StyledSecondaryTypography>
                            </Stack>
                            <StyledSecondaryTypography
                              sx={{
                                color: "#4D5761",
                                fontSize: "12px !important",
                              }}
                            >
                              XLS (max. 25 megabytes)
                            </StyledSecondaryTypography>
                          </Stack>
                        </Stack>
                      </Grid>
                    )}
                  </Dropzone>
                </Grid>
              )}
            </Box>
          ))}
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              p: 2,
              mt: 3,
              backgroundColor: "#FFFFFF",
            }}
          >
            {/* <Box sx={{ display: "flex", alignItems: "center" }}>
              <RefreshIcon style={{ marginRight: "5px" }} />
              <StyledSecondaryTypography>
                Last Updated at {moment.utc(new Date()).format("HH:mm A")}
              </StyledSecondaryTypography>
            </Box> */}
            <Button
              onClick={handleContinue}
              sx={{
                padding: "10px 16px",
                borderRadius: "8px",
                backgroundColor: "#0E5E84",
                color: "#FFF",
                fontSize: "14px",
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: "#022B3E",
                },
              }}
            >
              Continue
            </Button>
          </Box>
          {editModalName && (
            <Modal open={true}>
              <Box
                sx={{
                  position: "absolute",
                  border: "none",
                  width: "100%",
                  height: "90%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#FFFFFF",
                    width: "40%",
                    borderRadius: "8px",
                  }}
                >
                  <Box
                    sx={{
                      padding: "16px 24px 16px 24px",
                      borderBottom: "1px solid #F3F4F6",
                      borderRadius: "8px 8px 0px 0px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <PrimaryTypography>Edit Building Name</PrimaryTypography>
                    <Close
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setEditModalName(false);
                        setInputError("");
                      }}
                    />
                  </Box>
                  <Box sx={{ padding: "30px 30px 30px 30px" }}>
                    <Box>
                      <Box
                        sx={{
                          borderRadius: "8px",
                          marginTop: "10px",
                          marginRight: "30px",
                        }}
                      >
                        <Typography
                          style={{
                            fontWeight: 600,
                            fontSize: "14px",
                            color: "#111927",
                          }}
                        >
                          Building Name
                        </Typography>
                        <TextField
                          sx={{
                            backgroundColor: inputError ? "#FEE4E2" : "#F9FAFB",
                            "& .MuiInputBase-root": {
                              color: inputError ? "#912018" : "#111927",
                            },
                          }}
                          value={editData.building}
                          onChange={(event) => {
                            setEditData({
                              ...editData,
                              building: event.target.value,
                            });
                            setInputError("");
                          }}
                          placeholder="Enter the building name"
                          InputProps={{ disableUnderline: true }}
                          inputProps={{ maxLength: 20 }}
                          fullWidth
                        />
                        <ErrorTypography>{inputError}</ErrorTypography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderRadius: "0px 0px 8px 8px",
                        backgroundColor: "#FFFFFF",
                        paddingTop: "24px",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        onClick={() => {
                          setEditModalName(false);
                          setInputError("");
                        }}
                        sx={{
                          borderRadius: "8px",
                          border: "1px solid #D2D6DB",
                          padding: "10px 16px 10px 16px",
                          fontWeight: 600,
                          fontSize: "14px",
                          color: "#0E5E84",
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        sx={{
                          borderRadius: "8px",
                          padding: "10px 16px 10px 16px",
                          fontWeight: 600,
                          fontSize: "14px",
                          color: "#FFFFFF",
                          cursor: "pointer",
                          border: "none",
                          backgroundColor: "#0E5E84",
                          "&:hover": {
                            backgroundColor: "#022B3E",
                          },
                        }}
                        onClick={handleEditConfirm}
                      >
                        Confirm
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Modal>
          )}
        </>
      ) : (
        <Box sx={{ backgroundColor: "#FFFFFF", mt: 3 }}>
          <Box
            sx={{
              p: 2,
              borderRadius: "8px 8px 0 0 ",
              borderBottom: "1px solid #E5E7EB",
            }}
          >
            <SecondaryTypography>Setup Building</SecondaryTypography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Box
              sx={{
                py: 5,
                border: "1px solid #E5E7EB",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                rowGap: "10px",
              }}
            >
              <AddBuildingIcon />
              <StyledTypography>Create Buildings</StyledTypography>
              <StyledSecondaryTypography>
                Please add buildings by clicking below button.
              </StyledSecondaryTypography>
              <Button onClick={addMoreBuildings} variant="outlined">
                Create Buildings
              </Button>
            </Box>
          </Box>
          {declineModelOpen && (
            <Modal open={true}>
              <Box
                sx={{
                  position: "absolute",
                  border: "none",
                  width: "100%",
                  height: "90%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#FFFFFF",
                    width: "40%",
                    borderRadius: "8px",
                  }}
                >
                  <Box
                    sx={{
                      padding: "16px 24px 16px 24px",
                      borderBottom: "1px solid #F3F4F6",
                      borderRadius: "8px 8px 0px 0px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <PrimaryTypography>Create Building</PrimaryTypography>
                    <Close
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setDeclineModelOpen(false);
                        setBuildingCount(null);
                      }}
                    />
                  </Box>
                  <Box sx={{ padding: "30px 30px 30px 30px" }}>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          style={{
                            fontWeight: 400,
                            fontSize: "14px",
                            color: "#4D5761",
                          }}
                        >
                          Specify the number of primary buildings on your
                          property
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          borderRadius: "8px",
                          marginTop: "30px",
                          marginRight: "30px",
                        }}
                      >
                        <Typography
                          style={{
                            fontWeight: 600,
                            fontSize: "14px",
                            color: "#111927",
                          }}
                        >
                          Number of Buildings
                        </Typography>
                        <TextField
                          sx={{
                            backgroundColor: inputError ? "#FEE4E2" : "#F9FAFB",
                            "& .MuiInputBase-root": {
                              color: inputError ? "#912018" : "#111927",
                            },
                          }}
                          value={buildingCount}
                          onChange={(e) => {
                            setBuildingCount(e.target.value);
                            setInputError("");
                          }}
                          placeholder="Values of 1-99 only"
                          inputProps={{ maxLength: 2 }}
                          InputProps={{ disableUnderline: true }}
                          fullWidth
                        />
                        <ErrorTypography>{inputError}</ErrorTypography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                        borderRadius: "0px 0px 8px 8px",
                        backgroundColor: "#FFFFFF",
                        paddingTop: "24px",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        onClick={handleBuildingContinue}
                        sx={{
                          borderRadius: "8px",
                          padding: "10px 16px 10px 16px",
                          fontWeight: 600,
                          fontSize: "14px",
                          color: "#FFFFFF",
                          cursor: "pointer",
                          border: "none",
                          backgroundColor: "#0E5E84",
                          "&:hover": {
                            backgroundColor: "#022B3E",
                          },
                        }}
                      >
                        Continue
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Modal>
          )}
          {showModal && (
            <Modal open={true}>
              <Box
                sx={{
                  position: "absolute",
                  border: "none",
                  width: "100%",
                  height: "90%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#FFFFFF",
                    width: "40%",
                    borderRadius: "8px",
                  }}
                >
                  <Box
                    sx={{
                      padding: "16px 24px 16px 24px",
                      borderBottom: "1px solid #F3F4F6",
                      borderRadius: "8px 8px 0px 0px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <PrimaryTypography>Create Building</PrimaryTypography>
                    <Close
                      style={{ cursor: "pointer" }}
                      onClick={handleGoBack}
                    />
                  </Box>
                  <Box sx={{ padding: "30px 30px 30px 30px" }}>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#4D5761",
                            fontSize: "14px",
                            fontWeight: 400,
                          }}
                        >
                          You've indicated the presence of{" "}
                          <span
                            style={{
                              color: "#4D5761",
                              fontSize: "14px",
                              fontWeight: 800,
                            }}
                          >
                            {buildingCount} buildings
                          </span>{" "}
                          on your property. Please verify this information, as
                          it cannot be changed in the future.
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          borderRadius: "8px",
                          marginTop: "10px",
                          marginRight: "30px",
                        }}
                      ></Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderRadius: "0px 0px 8px 8px",
                        backgroundColor: "#FFFFFF",
                        paddingTop: "24px",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        onClick={handleGoBack}
                        sx={{
                          borderRadius: "8px",
                          border: "1px solid #D2D6DB",
                          padding: "10px 16px 10px 16px",
                          fontWeight: 600,
                          fontSize: "14px",
                          color: "#0E5E84",
                        }}
                      >
                        Go Back
                      </Button>
                      <Button
                        onClick={handleCreateBuilding}
                        sx={{
                          borderRadius: "8px",
                          padding: "10px 16px 10px 16px",
                          fontWeight: 600,
                          fontSize: "14px",
                          color: "#FFFFFF",
                          cursor: "pointer",
                          border: "none",
                          backgroundColor: "#0E5E84",
                          "&:hover": {
                            backgroundColor: "#022B3E",
                          },
                        }}
                      >
                        Create
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Modal>
          )}
        </Box>
      )}
    </>
  );
};

export default AddBuilding;
