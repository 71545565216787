import { createTheme } from "@mui/material/styles";
import styled from "@emotion/styled";
import {
  OutlinedInput,
  TableCell,
  TableRow,
  Typography,
  tableCellClasses,
  Switch,
} from "@mui/material";
import { ReactComponent as SelectArrow } from "../Icons/SelectArrow.svg";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0E5E84",
    },
    secondary: {
      main: "#000",
    },
    notify: {
      main: "#B42318",
    },
  },
  typography: {
    allVariants: {
      fontFamily: '"Inter", sans-serif',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "8px",
          height: "41px",
          padding: "10px 18px 10px 18px",
          fontWeight: "600",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
            outline: "none",
          },
        },
        Standard: {
          width: "fit-content",
          border: "none",
          backgroundColor: "#E7EFF3",
          color: "#0E5E84",
          "&:hover": {
            backgroundColor: "#CFDFE6",
          },
        },
        outlined: {
          "&:hover": {
            backgroundColor: "#F3F4F6",
          },
        },
        contained: {
          "&:hover": {
            backgroundColor: "#022B3E",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
        disableUnderline: true,
        fullWidth: true,
        autoComplete: false,
      },
      styleOverrides: {
        root: {
          backgroundColor: "#F9FAFB",
          borderRadius: "8px",
          padding: "8px",
          boxSizing: "border-box",
          color: "#111927",
          fontSize: "16px",
          fontWeight: 400,
          marginTop: "8px",
          "& input[type=number]": {
            "-moz-appearance": "textfield",
          },
          "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: "#F9FAFB",
          height: "46px",
          borderRadius: "8px",
          border: "none !important",
          padding: "8px",
          marginTop: "8px",
          width: "100%",
          positiion: "relative",
        },
        icon: {
          right: "8px", // Position the icon on the right
          top: "50%", // Center vertically
          transform: "translateY(-50%)", // Adjust vertical alignment
        },
        standard: {
          "&:focus": {
            backgroundColor: "#F9FAFB",
          },
        },
      },
      MenuProps: {
        PaperProps: {
          style: {
            marginTop: '8px', // Adjust this value for the desired gap
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          wordBreak: "break-word",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          outline: "none",
          boxShadow: "none",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: "1px solid #EAECF0",
          borderRadius: "12px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: "16px ",
          fontWeight: "600",
          letterSpacing: "0 ",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderTop: "1px solid #F3F4F6 !important",
          // width: '100%',
          marginTop: "13px",
          borderStyle: "none",
        },
      },
    },
    MUIRichTextEditor: {
      styleOverrides: {
        toolbar: {
          height: "50px",
          borderBottom: "1px solid #E5E7EB",
          display: "flex",
          alignItems: "center",
        },
        root: {
          minHeight: "252px",
          width: "100%",
          backgroundColor: "#F9FAFB",
          textAlign: "start",
          borderRadius: "8px",
        },
        editor: {
          width: "100%",
          textAlign: "start",
          padding: "12px 14px 12px 14px",
          direction: "ltr !important",
        },
        placeHolder: {
          color: "#9DA4AE",
          padding: "12px 14px 12px 14px",
        },
        anchorLink: {
          color: "#FFEB3B",
          textDecoration: "underline",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          // display: 'none !important',
        },
      },
    },
    MuiDateRangeCalendar: {
      styleOverrides: {
        root: {
          "&.MuiDateRangeCalendar-root > div:nth-child(1)": {
            color: "white !important",
            zIndex: "-100000 !important",
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          minWidth: "840px",
          overflowX: "auto",
          "::-webkit-scrollbar": { display: "none" },
        },
      },
    },
  },
});

export default theme;

//only for login/signup
export const StyledPrimaryTypography = styled(Typography)({
  color: "#101828",
  fontSize: "20px",
  fontWeight: 600,
  marginBottom: "5px",
});

export const StyledErrorTypography = styled(Typography)({
  color: "#912018",
  fontSize: "14px",
  fontWeight: "400",
  margin: "-8px -8px 8px 0px",
});

//common
export const PrimaryTypography = styled(Typography)({
  color: "#111927",
  fontSize: "18px",
  fontWeight: 600,
});

export const SecondaryTypography = styled(Typography)({
  color: "#111927",
  fontSize: "16px",
  fontWeight: 600,
});

export const StyledSecondaryTypography = styled(Typography)({
  color: "#6C737F",
  fontSize: "14px",
  fontWeight: 400,
});

export const StyledPrimaryDefaultTypography = styled(Typography)({
  color: "#6C737F",
  fontSize: "14px",
  fontWeight: 600,
});
export const StyledSecondaryServiceTypography = styled(Typography)({
  color: "#4D5761",
  fontSize: "14px",
  fontWeight: 400,
});
export const StyledSecondaryBookingTypography = styled(Typography)({
  color: "#4D5761",
  fontSize: "14px",
  fontWeight: 600,
});
export const StyledSecondaryDefaultTypography = styled(Typography)({
  color: "#4D5761",
  fontSize: "16px",
  fontWeight: 400,
});
export const StyledTypography = styled(Typography)({
  color: "#101828",
  fontSize: "14px",
  fontWeight: 600,
});

export const StyledDefaultTypography = styled(Typography)({
  color: "#0E4965",
  fontSize: "14px",
  fontWeight: 600,
});

export const TypographyDefault = styled(Typography)({
  color: "#111927",
  fontSize: "14px",
  fontWeight: 400,
});
export const SecondaryTypographyDefault = styled(Typography)({
  color: "#111927",
  fontSize: "14px",
  fontWeight: 600,
});
export const styledTyphographyDefault = styled(Typography)({
  color: "#344054",
  fontSize: "14px",
  fontWeight: 500,
});

export const StyledOutlinedInput = styled(OutlinedInput)({
  height: "40px",
  borderColor: "#D2D6DB",
  borderRadius: "8px",
  minWidth: "250px",
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#D2D6DB",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#D2D6DB",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#D2D6DB",
  },
  "&.MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#D2D6DB",
    },
    "&:hover fieldset": {
      borderColor: "#D2D6DB",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D2D6DB",
    },
  },
});

export const TypoGraph = styled(Typography)({
  color: "#6C737F",
  fontSize: "12px",
  fontWeight: 400,
});

export const StyledTableCell = styled(TableCell)({
  color: "#111927",
  // padding: '13px',
  textAlign: "left",
  wordBreak: "break-word",
  [`&.${tableCellClasses.head}`]: {
    fontSize: "13px",
    fontWeight: 600,
    backgroundColor: "#F9FAFB",
    padding: "0px 15px 0px 15px",
    height: "46px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "15px",
    fontWeight: 400,
    cursor: "pointer",

    padding: "16px 15px",
    height: "44px",
  },
});

export const StyledTableRow = styled(TableRow)({
  "&:nth-of-type(even)": {
    backgroundColor: "#F9FAFB",
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "#FFF",
  },
});

//validation error text
export const ErrorTypography = styled(Typography)({
  color: "#912018",
  fontSize: "14px",
  fontWeight: "400",
  marginTop: "8px",
});

//only for property stepper
export const StepperPrimaryTypography = styled(Typography)({
  color: "#FFF",
  fontSize: "16px",
  fontWeight: 600,
});

export const StepperDisabledPrimaryTypography = styled(Typography)({
  color: "#D2D6DB",
  fontSize: "16px",
  fontWeight: 600,
});

export const StepperSecondaryTypography = styled(Typography)({
  color: "#FFFFFF",
  fontSize: "14px",
  fontWeight: 600,
});

export const StepperDisabledSecondaryTypography = styled(Typography)({
  color: "#D2D6DB",
  fontSize: "14px",
  fontWeight: 600,
});

export const StepperTypogreaphy = styled(Typography)({
  color: "#FFFFFF",
  fontSize: "12px",
  fontWeight: 400,
});

export const StepperDisabledTypogreaphy = styled(Typography)({
  color: "#9DA4AE",
  fontSize: "12px",
  fontWeight: 400,
});

export const DatePickerStyle = {
  ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:before": {
    borderBottom: "none",
  },
  ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:after": {
    borderBottom: "none",
  },
  ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:hover": {
    borderBottom: "none",
  },
  ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before":
    { borderBottom: "none" },
  ".css-1ob5wwi::after": { borderBottom: "none" },
  ".css-1ob5wwi.Mui-error::after": { borderBottomColor: "none" },
};

export const DatePickerStyles = {
  backgroundColor: "#F3F4F6 ",
  marginLeft: "12px",
  width: "0",
  position: "aboslute",
  left: "-115px",
  top: "-4px",
  "&:hover": {
    backgroundColor: "#F3F4F6 !important",
  },
  "& input": {
    display: "none",
  },
  ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:before": {
    borderBottom: "none",
  },
  ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:after": {
    borderBottom: "none",
  },
  ".css-1bwf3y6-MuiInputBase-root-MuiInput-root:hover": {
    borderBottom: "none",
  },
};

// Switch
export const StyledSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: "44px",
  height: "24px",
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 3,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#0E5E84",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 18,
    height: 18,
  },
  "& .MuiSwitch-track": {
    borderRadius: "100px",
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 300,
    }),
  },
}));
