import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as BackArrow } from "../../../../Icons/BorderBackArrow.svg";
import { ReactComponent as MoreInfoIcon } from "../../../../Icons/MoreInfo.svg";
import { ReactComponent as CommonAreaIcon } from "../../../../Icons/CommonArea.svg";
import { ReactComponent as CheckboxIcon } from "../../../../Icons/Checkbox.svg";
import { ReactComponent as RefreshIcon } from "../../../../Icons/Refresh.svg";
import { ReactComponent as Arrow } from "../../../../Icons/AccordionArrow.svg";
import { ReactComponent as ManagementIcon } from "../../../../Icons/ManagementIcon.svg";
import { ReactComponent as OperationIcon } from "../../../../Icons/Operation.svg";
import { ReactComponent as GroundStaffIcon } from "../../../../Icons/GroundStaff.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactComponent as MoreInfoWOBIcon } from "../../../../Icons/MoreInfoWOB.svg";
import {
  ErrorTypography,
  PrimaryTypography,
  SecondaryTypography,
  StyledSecondaryTypography,
  StyledTypography,
  TypographyDefault,
} from "../../../../Theme";
import {
  Stack,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import SystemDetailsModal from "../../../../CommonComponents/SystemDetailsModal";
import {
  addUserAndRole,
  getUserAndRole,
  setTabValue,
  editUserAndRole,
  deleteUserAndRole,
} from "../../../../Redux/Feature/PropertyDetails/propertyDetailsSlice";
import AlertModal, {
  PostLoaderModal,
} from "../../../../CommonComponents/modal";
import axiosInstance from "../../../../Redux/AxiosInstance";

const EditUserRole = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addblocks, setaddblocks] = useState({
    Details: true,
    Review_Information: false,
  });
  const { LanguageData } = useSelector((state) => state.Language);
  const blocks = [
    { status: addblocks.Details, tle: "Details" },
    { status: addblocks.Review_Information, tle: "Review Information" },
  ];

  const managementDefaultData = {
    role: "",
    info: [],
  };
  const operationDefaultData = {
    role: "",
    info: [],
  };
  const groundstaffDefaultData = {
    role: "",
    info: [],
  };
  const [managementdefaultdata, setmanagementdefaultdata] = useState(
    managementDefaultData
  );
  const [operationdefaultdata, setoperationdefaultdata] =
    useState(operationDefaultData);
  const [groundstaffdefaultdata, setgroundstaffdefaultdata] = useState(
    groundstaffDefaultData
  );

  //User and Role Data
  const [managementdata, setmanagementdata] = useState([]);
  const [operationdata, setoperationdata] = useState([]);
  const [groundstaffdata, setgroundstaffdata] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [add, setadd] = useState({
    management: true,
    operation: false,
    groundstaff: false,
  });
  const [errortext, seterrortext] = useState();
  const [data, setdata] = useState();
  const [contineerr, setcontinueerr] = useState(false);
  const [addrole, setaddrole] = useState({
    management: false,
    operation: false,
    groundstaff: false,
  });
  const [expand, setexpand] = useState(true);
  const [editManagementExpand, setEditManagementExpand] = useState([]);
  const [editOperationExpand, setEditOperationExpand] = useState([]);
  const [editGroundStaffExpand, setEditGroundStaffExpand] = useState([]);

  const [editedData, seteditedData] = useState({ index: "", state: "" });
  //get id of edited rights
  const [getId, setgetId] = useState([]);
  const [deletingIndex, setDeletingIndex] = useState();
  const {
    addUserAndRoleStatus,
    UserAndRoleList,
    TabValue,
    deleteUserAndRoleStatus,
    editUserAndRoleStatus,
    propertyId,
  } = useSelector((state) => state.PropertyDetails);
  const [editmanagementData, seteditmanagementdata] = useState(managementdata);
  const [editoperationdata, seteditoperationdata] = useState(operationdata);
  const [editgroundstaffdata, seteditgroundstaffdata] =
    useState(groundstaffdata);
  const { savePropertyDetailsAsDraftStatus } = useSelector(
    (state) => state.Login
  );
  const [postLoader, SetPostLoader] = useState(false);
  //default data

  const commonmodal = {
    management: {
      label: "Management",
      description:
        "Management department is the department which represents the highest level of authority within the Loby system, people tagged to management should be employees which requires statistics, have the right to onboard/ offboard all users, they are users who should not need to act on resident feedback or take action, only overall statistical data like monthly reports. ",
    },
    operations: {
      label: "Operations",
      description:
        "Operations department represents the operations team users who require to filter and act on tasks, and assign them to further employees. Examples would include juristics adminstrators who will recieve feedback and calls from tenants, and assign employees like technicians or cleaners to act on it. ",
    },
    groundstaff: {
      label: "Ground Staff",
      description:
        "Ground Staff represents the team or users who like act on assigned tasks. This will include cleaning department, fixing and plumbing department and can include both supervisors and junior staff of cleaners, technicians, security who are in charge of acting upon feedback and ensuring residents safety & comfort. ",
    },
    access: {
      label: "Access",
      description:
        "Access only allows the user to view the function on the system as well as complete assigned tasks, they will not be able to manage or edit the function itself.",
    },
    manage: {
      label: "Manage",
      description:
        "Manage is an advanced permission which allows user to edit the function like adding/ create, assigning users, deleting the function itself.",
    },
  };
  const getDefaultData = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}getDefaultPermissionDatas?department_type=NA`
      );
      const mdata = response.data.data.permission[0];
      const odata = response.data.data.permission[1];
      const gdata = response.data.data.permission[2];

      setmanagementdefaultdata((prev) => ({
        ...prev,
        info: mdata.service,
        d_name: mdata.department_name,
        d_id: mdata.id,
      }));
      setoperationdefaultdata((prev) => ({
        ...prev,
        info: odata.service,
        d_name: odata.department_name,
        d_id: odata.id,
      }));
      setgroundstaffdefaultdata((prev) => ({
        ...prev,
        info: gdata.service,
        d_name: gdata.department_name,
        d_id: gdata.id,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDefaultData();
    if (UserAndRoleList?.length == 0) {
      dispatch(getUserAndRole(propertyId));
    }
  }, [propertyId]);

  useEffect(() => {
    SetPostLoader(false);
    setmanagementdata(UserAndRoleList?.data?.property_role[0].role);
    setoperationdata(UserAndRoleList?.data?.property_role[1].role);
    setgroundstaffdata(UserAndRoleList?.data?.property_role[2].role);
  }, [UserAndRoleList]);

  useEffect(() => {
    if (addUserAndRoleStatus?.success || deleteUserAndRoleStatus?.success) {
      dispatch(getUserAndRole(propertyId));
      // setShowModal(false)
      // setEditExpand(false)
    }

    if (editUserAndRoleStatus?.success) {
      dispatch(getUserAndRole(propertyId));
      editedData?.state((prev) => {
        const data = prev.map((itm, sind) => {
          if (editedData?.index == sind) {
            return { ...itm, expand: false };
          }
          return itm;
        });
        return data;
      });
    }
  }, [addUserAndRoleStatus, editUserAndRoleStatus, deleteUserAndRoleStatus]);

  useEffect(() => {
    seteditmanagementdata(managementdata);
    seteditoperationdata(operationdata);
    seteditgroundstaffdata(groundstaffdata);

    const editManagementExpand = managementdata?.map((itm, index) => ({
      id: itm?.id,
      key: index,
      expand: false,
    }));
    const editOperationExpand = operationdata?.map((itm, index) => ({
      id: itm?.id,
      key: index,
      expand: false,
    }));
    const editGroundStaffExpand = groundstaffdata?.map((itm, index) => ({
      id: itm?.id,
      key: index,
      expand: false,
    }));

    setEditManagementExpand(editManagementExpand);
    setEditOperationExpand(editOperationExpand);
    setEditGroundStaffExpand(editGroundStaffExpand);
  }, [managementdata]);

  let modalDatas = {
    label: "Are you sure you wish to delete the role?",
    descriptionOne: "Please confirm that you wish to delete the role.",
    descriptionTwo: "",
    buttonOne: {
      variant: "outlined",
      color: "#0E5E84",
      backgroundColor: "#FFFFFF",
      text: "Cancel",
    },
    buttonTwo: {
      variant: "contained",
      color: "#FFFFFF",
      backgroundColor: "#B42318",
      text: "Delete Now",
    },
  };

  const commoninputblock = (
    data,
    SaveRole,
    handlechange,
    setaddrole,
    errortext,
    setitem,
    block,
    expand,
    handleexpand
  ) => {
    return (
      <Box
        sx={{
          borderRadius: "12px",
          marginBottom: "20px",
          border: "1px solid #E5E7EB",
        }}
        key={1}
      >
        <Accordion
          expanded={expand}
          onChange={() => {
            handleexpand(block);
          }}
          sx={{ background: "none" }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <SecondaryTypography>
              {data?.role?.length > 0 ? data?.role : "Create New Role"}
            </SecondaryTypography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container xs={12} gap={3}>
              <Grid xs={12}>
                <StyledTypography>
                  {LanguageData?.Roles || "Roles"}{" "}
                </StyledTypography>
                <TextField
                  placeholder="Please give a role name"
                  name="role"
                  value={data?.role}
                  sx={{
                    "& .MuiInputBase-root": {
                      color: errortext ? "#912018" : "#111927",
                    },
                    backgroundColor: Boolean(errortext) ? "#FEE4E2" : "",
                  }}
                  inputProps={{ maxLength: 50 }}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment
                        sx={{ position: "absolute", right: 0, top: "-24px" }}
                      >
                        {data?.role?.length}/50
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    handlechange("role", e);
                  }}
                  fullWidth
                />
                <ErrorTypography>{errortext}</ErrorTypography>
              </Grid>
              <Grid xs={12}>
                <Stack direction="row">
                  <Grid xs={8}>
                    <TypographyDefault sx={{ fontWeight: "600 !important" }}>
                      {LanguageData?.Functions || "Functions"}
                    </TypographyDefault>
                  </Grid>
                  <Grid xs={4}>
                    <Stack
                      direction="row"
                      justifyContent="space-evenly"
                      alignItems="center"
                    >
                      <Stack direction="row" alignItems="center" gap="5px">
                        <TypographyDefault
                          ml={1}
                          sx={{ fontWeight: "600 !important" }}
                        >
                          {LanguageData?.Access || "Access"}
                        </TypographyDefault>
                        <SystemDetailsModal commonmodal={commonmodal.access} />
                      </Stack>
                      <Stack direction="row" alignItems="center" gap="5px">
                        <TypographyDefault
                          sx={{ fontWeight: "600 !important" }}
                        >
                          {LanguageData?.Manage || "Manage"}{" "}
                        </TypographyDefault>
                        <SystemDetailsModal commonmodal={commonmodal.manage} />
                      </Stack>
                    </Stack>
                  </Grid>
                </Stack>
              </Grid>
              <Grid xs={12}>
                {data?.info?.map((itm, index) => {
                  return (
                    <Stack direction="row" alignItems="center">
                      <Grid xs={8}>
                        <Stack my={2} gap="5px">
                          <TypographyDefault>
                            {itm?.service_name}
                          </TypographyDefault>
                          <StyledSecondaryTypography
                            sx={{ fontSize: "12px !important" }}
                          >
                            {itm?.description}
                          </StyledSecondaryTypography>
                        </Stack>
                      </Grid>

                      <Grid xs={4}>
                        <Stack
                          my={2}
                          direction="row"
                          justifyContent="space-evenly"
                          alignItems="center"
                        >
                          {/* <Checkbox checked={itm?.rights[0].enabled} icon={<CheckboxIcon />} onChange={(e) => handlechange(index, e, 0)} name='access' disabled={itm?.rights[0].editable === true ? false : true} />
                                                    <Checkbox checked={itm?.rights[1].enabled} icon={<CheckboxIcon />} onChange={(e) => handlechange(index, e, 1)} name='manage' disabled={itm?.rights[1].editable === true ? false : true} /> */}
                          {itm.rights.map((rtsitm, sindex) => {
                            return (
                              <Checkbox
                                checked={rtsitm?.enabled}
                                icon={
                                  <CheckboxIcon
                                    fill={
                                      rtsitm?.editable === true
                                        ? "none"
                                        : "#D2D6DB"
                                    }
                                  />
                                }
                                onChange={(e) =>
                                  handlechange(
                                    index,
                                    e,
                                    sindex,
                                    itm.id,
                                    rtsitm.id
                                  )
                                }
                                name="access"
                                disabled={
                                  rtsitm?.editable === true ? false : true
                                }
                              />
                            );
                          })}
                        </Stack>
                      </Grid>
                    </Stack>
                  );
                })}
              </Grid>
              <Grid xs={12}>
                <Stack direction="row" justifyContent="end" gap={2} my={2}>
                  <Button
                    sx={{
                      padding: "10px 18px",
                      borderRadius: "8px",
                      backgroundColor: "#0E5E84",
                      color: "#FFF",
                      fontSize: "14px",
                      fontWeight: 600,
                      "&:hover": {
                        backgroundColor: "#022B3E",
                      },
                    }}
                    onClick={() => {
                      SaveRole(setitem, block, data.d_id);
                    }}
                  >
                    {LanguageData?.Save || "Save"}
                  </Button>
                  <Button
                    sx={{
                      width: "85px",
                      height: "41px",
                      color: "#0E5E84",
                      backgroundColor: "#E7EFF3",
                      "&:hover": {
                        backgroundColor: "#CFDFE6",
                      },
                    }}
                    onClick={() => {
                      handleexpand(block);
                    }}
                  >
                    {LanguageData?.Delete || "Delete"}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  };

  const handleexpand = (block) => {
    setaddrole((prev) => {
      return { ...prev, [block]: false };
    });

    if (block === "management" && managementdata.length === 0) {
      setadd((prev) => {
        return { ...prev, management: false };
      });
    } else if (block === "operation" && operationdata.length === 0) {
      setadd((prev) => {
        return { ...prev, operation: false };
      });
    } else if (block === "groundstaff" && groundstaffdata.length === 0) {
      setadd((prev) => {
        return { ...prev, groundstaff: false };
      });
    }
  };

  useEffect(() => {
    if (add.management === true) {
      setdata(managementdefaultdata);
    }
    if (add.operation === true) {
      setdata(operationdefaultdata);
    }
    if (add.groundstaff === true) {
      setdata(groundstaffdefaultdata);
    }
  }, [add]);

  const handlechange = (ind, e, subind, service_id, rights_id) => {
    const { name, checked, value } = e.target;
    if (ind === "role") {
      seterrortext("");
    }
    setdata((prev) => {
      if (ind === "role") {
        return { ...prev, [name]: value };
      } else {
        const item = data.info.map((itm, index) => {
          if (ind === index) {
            const sitm = itm.rights.map((sitm, sindex) => {
              if (subind === sindex) {
                return { ...sitm, enabled: checked };
              }
              return sitm;
            });
            return { ...itm, rights: sitm };
          }
          return itm;
        });
        return { ...prev, info: item };
      }
    });
  };

  const SaveRole = (itm, block, id) => {
    if (data.role.length > 0) {
      itm((prev) => {
        return [...prev, data];
      });
      setaddrole((prev) => ({ ...prev, [block]: false }));
      seterrortext("");

      var arr = [];
      const Dta = data.info.map((itm) => {
        const rights = itm.rights.map((sitm) => {
          if (sitm.enabled === true) {
            arr.push({ service_id: itm.id, rights_id: sitm.id });
          }
        });
      });

      const adddata = {
        title: data.role,
        description: "description",
        department_id: id,
        access: arr,
      };
      SetPostLoader(true);
      dispatch(addUserAndRole({ propertyId, adddata }));
    } else {
      seterrortext("Error! No Inputs detected");
    }
    setcontinueerr(false);
  };
  // var editarr = []

  const editData = (
    index,
    title,
    e,
    subindex,
    setstate,
    state,
    rightsindex
  ) => {
    const { name, value, checked } = e.target;

    if (name === "title") {
      setstate((prev) => {
        const item = prev.map((itm, ind) => {
          if (index === ind) {
            return { ...itm, [name]: value };
          }
          return itm;
        });
        return item;
      });
      seterrortext("");
    } else {
      const data = state.map((itm, ind) => {
        if (index === ind) {
          const subdata = itm.service.map((sitm, sind) => {
            if (subindex === sind) {
              const ssitm = sitm.rights.map((ssitm, ssind) => {
                if (rightsindex === ssind) {
                  const editedIds = getId.find((itm) => {
                    return (
                      itm.service_id === sitm.id && itm.rights_id === ssitm.id
                    );
                  });
                  if (!Boolean(editedIds)) {
                    setgetId((prev) => [
                      ...prev,
                      { service_id: sitm.id, rights_id: ssitm.id },
                    ]);
                  } else if (Boolean(editedIds)) {
                    const d = getId.filter((itm) => {
                      return !(
                        itm.service_id === sitm.id && itm.rights_id === ssitm.id
                      );
                    });
                    setgetId(d);
                  }
                }
              });
            }
          });
        }
      });

      setstate((prev) => {
        const updatedItems = prev.map((itm, ind) => {
          if (index === ind) {
            const updatedServices = itm.service.map((subitm, subind) => {
              if (subind === subindex) {
                const updatedRights = subitm.rights.map((right, rightIndex) => {
                  if (rightsindex === rightIndex) {
                    return { ...right, enabled: checked };
                  }
                  return right;
                });

                if (rightsindex === 0 && !checked) {
                  updatedRights[1] = { ...updatedRights[1], enabled: false };
                } else if (rightsindex === 1 && updatedRights[1]?.enabled) {
                  updatedRights[0] = { ...updatedRights[0], enabled: true };
                }

                return { ...subitm, rights: updatedRights };
              }
              return subitm;
            });
            return { ...itm, service: updatedServices };
          }
          return itm;
        });
        return updatedItems;
      });
    }
  };

  const editSave = (index, state) => {
    const { title, department_id, id } = state[index];

    if (title.length > 0) {
      const data = {
        id: id,
        body: {
          title: title,
          description: "hgj",
          department_id: department_id,
          access: getId,
        },
      };
      SetPostLoader(true);
      dispatch(editUserAndRole({ propertyId, data }));
    } else {
      seterrortext("Error! No Inputs detected");
    }
  };

  useEffect(() => {
    if (managementdata?.length === 0) {
      setadd((prev) => {
        return { ...prev, management: false };
      });
    }
    if (operationdata?.length === 0) {
      setadd((prev) => {
        return { ...prev, operation: false };
      });
    }
    if (groundstaffdata?.length === 0) {
      setadd((prev) => {
        return { ...prev, groundstaff: false };
      });
    }
  }, [managementdata, operationdata, groundstaffdata]);

  const addCommonblock = (value) => {
    if (value === "management") {
      setadd({ management: true, operation: false, groundstaff: false });
      setaddrole({ management: true, operation: false, groundstaff: false });
    } else if (value === "operation") {
      setadd({ management: false, operation: true, groundstaff: false });
      setaddrole({ management: false, operation: true, groundstaff: false });
    } else if (value === "groundstaff") {
      setadd({ management: false, operation: false, groundstaff: true });
      setaddrole({ management: false, operation: false, groundstaff: true });
    }
  };

  const Continue = (nxtval) => {
    if (
      managementdata?.length > 0 &&
      operationdata?.length > 0 &&
      groundstaffdata?.length > 0
    ) {
      if (nxtval == "Review Information") {
        setaddblocks({ Details: false, Review_Information: true });
      } else if (nxtval == "Details") {
        setaddblocks({ Details: true, Review_Information: false });
      }
    } else {
      setcontinueerr(true);
    }
  };
  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const Deletesinglerole = () => {
    SetPostLoader(true);
    dispatch(deleteUserAndRole({ propertyId, deletingIndex }));
  };

  const handleContinue = () => {
    dispatch(setTabValue(3));

    navigate("/settings");
  };

  const handleEditExpand = (e, expanded, index, state) => {
    state((prev) => {
      const data = prev.map((itm, sind) => {
        if (index == sind) {
          return { ...itm, expand: expanded };
        }
        return itm;
      });
      return data;
    });
  };
  const handleChangeCheckBox = (sitm, rtsitm) => {
    console.log("br", sitm, rtsitm);
    if (!sitm?.rights[0]?.enabled) {
      return false;
    } else if (sitm?.rights[1]?.enabled) {
      return true;
    }

    return rtsitm?.enabled;
  };
  const Details = () => {
    return (
      <>
        {showModal && (
          <AlertModal
            modalDatas={modalDatas}
            confirmFunction={Deletesinglerole}
            closeFunction={handleShowModal}
          />
        )}

        <Box sx={{ borderRadius: "8px" }}>
          <Grid container gap="30px">
            <Grid xs={12} sx={{ borderRadius: "8px" }}>
              <Stack
                sx={{
                  borderRadius: "8px 8px 0 0",
                  borderBottom: "1px solid #E5E7EB",
                }}
                direction="row"
                bgcolor="#FCFCFD"
                alignItems="center"
                padding="16px"
              >
                <Box flexGrow={1}>
                  <Stack direction="row" alignItems="center">
                    <PrimaryTypography>
                      {LanguageData?.home_tab_management || "Management"}{" "}
                      <span style={{ color: "#9DA4AE", paddingLeft: "7px" }}>
                        {" "}
                        |
                      </span>{" "}
                    </PrimaryTypography>
                    <StyledSecondaryTypography ml={1.2}>
                      {managementdata?.length}{" "}
                      {LanguageData?.Roles_no || "Roles"}
                    </StyledSecondaryTypography>
                  </Stack>
                </Box>
                <SystemDetailsModal commonmodal={commonmodal.management} />
              </Stack>
              <Box
                component="div"
                padding="24px 10px 24px 10px"
                sx={{
                  backgroundColor: "#FFFFFF !important",
                  borderRadius: "0 0 8px 8px",
                }}
              >
                <Box
                  component="div"
                  mb={3}
                  sx={{
                    backgroundColor: "#FFFFFF !important",
                    borderRadius: "12px",
                  }}
                >
                  {!add?.management && !(managementdata?.length > 0) && (
                    <Box
                      gap="30px"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "1px solid #E5E7EB",
                        borderRadius: "12px",
                        padding: "24px 10px 24px 10px",
                      }}
                    >
                      <ManagementIcon />
                      <Box
                        maxWidth="728px"
                        gap="12px"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TypographyDefault
                          sx={{ fontWeight: "600 !important" }}
                        >
                          {LanguageData?.home_tab_management || "Management"}{" "}
                        </TypographyDefault>
                        <TypographyDefault>
                          {LanguageData?.Manage_Des ||
                            "Management department is the department which represents the highest level of authority within the Loby system, people tagged to management should be employees which requires statistics, have the right to onboard/ offboard all users, they are users who should not need to act on resident feedback or take action,only overall statistical data like monthly reports."}
                        </TypographyDefault>
                        <Button
                          variant="outlined"
                          sx={{ border: " 1px solid #D2D6DB" }}
                          onClick={() => addCommonblock("management")}
                        >
                          {LanguageData?.Add_Role || "Add Role"}
                        </Button>
                      </Box>
                    </Box>
                  )}
                  {(add?.management || managementdata?.length > 0) && (
                    <Box
                      sx={{
                        borderRadius: "12px",
                        marginBottom: "20px",
                        border: "1px solid #E5E7EB",
                      }}
                    >
                      <Accordion sx={{ background: "none" }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <SecondaryTypography>
                            {LanguageData?.Business_Account ||
                              "Business Account Owner (Default)"}
                          </SecondaryTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container xs={12} gap={3}>
                            <Grid xs={12}>
                              <StyledTypography>
                                {LanguageData?.Roles || "Roles"}{" "}
                              </StyledTypography>
                              <TextField
                                disabled
                                value="Business Account Owner (Default)"
                                inputProps={{ maxLength: 30 }}
                                InputProps={{ disableUnderline: true }}
                                fullWidth
                              />
                            </Grid>
                            <Grid xs={12}>
                              <Stack direction="row">
                                <Grid xs={8}>
                                  <TypographyDefault
                                    sx={{ fontWeight: "600 !important" }}
                                  >
                                    {LanguageData?.Functions || "Functions"}
                                  </TypographyDefault>
                                </Grid>
                                <Grid xs={4}>
                                  <Stack
                                    direction="row"
                                    justifyContent="space-evenly"
                                    alignItems="center"
                                  >
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      gap="5px"
                                    >
                                      <TypographyDefault
                                        ml={1}
                                        sx={{ fontWeight: "600 !important" }}
                                      >
                                        {LanguageData?.Access || "Access"}
                                      </TypographyDefault>
                                      <SystemDetailsModal
                                        commonmodal={commonmodal.access}
                                      />
                                    </Stack>
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      gap="5px"
                                    >
                                      <TypographyDefault
                                        sx={{ fontWeight: "600 !important" }}
                                      >
                                        {LanguageData?.Manage || "Manage"}
                                      </TypographyDefault>
                                      <SystemDetailsModal
                                        commonmodal={commonmodal.manage}
                                      />
                                    </Stack>
                                  </Stack>
                                </Grid>
                              </Stack>
                            </Grid>
                            <Grid xs={12}>
                              {managementdefaultdata?.info.map((itm, index) => {
                                return (
                                  <Stack
                                    key={index}
                                    direction="row"
                                    alignItems="center"
                                  >
                                    <Grid xs={8}>
                                      <Stack my={2} gap="5px">
                                        <TypographyDefault>
                                          {itm.title}
                                        </TypographyDefault>
                                        <StyledSecondaryTypography
                                          sx={{ fontSize: "12px !important" }}
                                        >
                                          {itm.description}
                                        </StyledSecondaryTypography>
                                      </Stack>
                                    </Grid>
                                    <Grid xs={4}>
                                      <Stack
                                        my={2}
                                        direction="row"
                                        justifyContent="space-evenly"
                                        alignItems="center"
                                      >
                                        <Checkbox
                                          checked
                                          icon={<CheckboxIcon />}
                                          disabled
                                        />
                                        <Checkbox
                                          checked
                                          icon={<CheckboxIcon />}
                                          disabled
                                        />
                                      </Stack>
                                    </Grid>
                                  </Stack>
                                );
                              })}
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  )}
                  {editmanagementData?.map((itm, index) => {
                    console.log("editmanagementData", editmanagementData);
                    const { id, department_id } = itm;
                    return (
                      <Box
                        key={index}
                        sx={{
                          borderRadius: "12px",
                          marginBottom: "10px",
                          border: "1px solid #E5E7EB",
                        }}
                      >
                        <Accordion
                          defaultExpanded={false}
                          expanded={
                            Boolean(editManagementExpand[index]?.expand)
                              ? editManagementExpand[index]?.expand
                              : false
                          }
                          onChange={(e, expanded) =>
                            handleEditExpand(
                              e,
                              expanded,
                              index,
                              setEditManagementExpand
                            )
                          }
                          sx={{ background: "none" }}
                        >
                          <AccordionSummary
                            onClick={() => {
                              setgetId([]);
                              seteditmanagementdata(managementdata);
                              seterrortext("");
                            }}
                            expandIcon={<ExpandMoreIcon />}
                          >
                            {console.log("title", itm.title)}
                            <SecondaryTypography>
                              {itm.title}
                            </SecondaryTypography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container xs={12} gap={3}>
                              <Grid xs={12}>
                                <StyledTypography>
                                  {LanguageData?.Roles || "Roles"}{" "}
                                </StyledTypography>
                                <TextField
                                  name="title"
                                  value={itm?.title}
                                  sx={{
                                    "& .MuiInputBase-root": {
                                      color: errortext ? "#912018" : "#111927",
                                    },
                                    backgroundColor: Boolean(errortext)
                                      ? "#FEE4E2"
                                      : "",
                                  }}
                                  onChange={(e) => {
                                    editData(
                                      index,
                                      "role",
                                      e,
                                      "",
                                      seteditmanagementdata,
                                      "",
                                      ""
                                    );
                                  }}
                                  inputProps={{ maxLength: 30 }}
                                  InputProps={{
                                    disableUnderline: true,
                                    endAdornment: (
                                      <InputAdornment
                                        sx={{
                                          position: "absolute",
                                          right: 0,
                                          top: "-15px",
                                        }}
                                      >
                                        {itm?.title?.length}/50
                                      </InputAdornment>
                                    ),
                                  }}
                                  fullWidth
                                />
                              </Grid>
                              <ErrorTypography>{errortext}</ErrorTypography>
                              <Grid xs={12}>
                                <Stack direction="row">
                                  <Grid xs={8}>
                                    <TypographyDefault
                                      sx={{ fontWeight: "600 !important" }}
                                    >
                                      {LanguageData?.Functions || "Functions"}
                                    </TypographyDefault>
                                  </Grid>
                                  <Grid xs={4}>
                                    <Stack
                                      direction="row"
                                      justifyContent="space-evenly"
                                      alignItems="center"
                                    >
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        gap="5px"
                                      >
                                        <TypographyDefault
                                          ml={1}
                                          sx={{ fontWeight: "600 !important" }}
                                        >
                                          {LanguageData?.Access || "Access"}
                                        </TypographyDefault>
                                        <SystemDetailsModal
                                          commonmodal={commonmodal.access}
                                        />
                                      </Stack>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        gap="5px"
                                      >
                                        <TypographyDefault
                                          sx={{ fontWeight: "600 !important" }}
                                        >
                                          {LanguageData?.Manage || "Manage"}
                                        </TypographyDefault>
                                        <SystemDetailsModal
                                          commonmodal={commonmodal.manage}
                                        />
                                      </Stack>
                                    </Stack>
                                  </Grid>
                                </Stack>
                              </Grid>
                              <Grid xs={12}>
                                {itm?.service?.map((sitm, subindex) => {
                                  {
                                    console.log("sitm", sitm);
                                  }
                                  return (
                                    <Stack
                                      key={subindex}
                                      direction="row"
                                      alignItems="center"
                                    >
                                      <Grid xs={8}>
                                        <Stack my={2} gap="5px">
                                          <TypographyDefault>
                                            {sitm.service_name}
                                          </TypographyDefault>
                                          <StyledSecondaryTypography
                                            sx={{ fontSize: "12px !important" }}
                                          >
                                            {sitm.description}
                                          </StyledSecondaryTypography>
                                        </Stack>
                                      </Grid>

                                      <Grid xs={4}>
                                        <Stack
                                          my={2}
                                          direction="row"
                                          justifyContent="space-evenly"
                                          alignItems="center"
                                        >
                                          {sitm.rights.map(
                                            (rtsitm, ssindex) => {
                                              {
                                                console.log("rts", rtsitm);
                                              }
                                              return (
                                                <Checkbox
                                                  checked={rtsitm?.enabled}
                                                  icon={
                                                    <CheckboxIcon
                                                      fill={
                                                        rtsitm?.editable ===
                                                        true
                                                          ? "none"
                                                          : "#D2D6DB"
                                                      }
                                                    />
                                                  }
                                                  onChange={(e) =>
                                                    editData(
                                                      index,
                                                      sitm.title,
                                                      e,
                                                      subindex,
                                                      seteditmanagementdata,
                                                      editmanagementData,
                                                      ssindex
                                                    )
                                                  }
                                                  name="access"
                                                  disabled={
                                                    rtsitm?.editable === true
                                                      ? false
                                                      : true
                                                  }
                                                />
                                              );
                                            }
                                          )}
                                          {/* <Checkbox checked={itm.rights[0].enabled} icon={<CheckboxIcon />} onChange={(e) => editData(index, sitm.title, e, subindex, setmanagementdata, 0)} name='access' disabled={itm?.rights[0].editable === true ? false : true} />
                                                                            <Checkbox checked={itm.rights[1].enabled} icon={<CheckboxIcon />} onChange={(e) => editData(index, sitm.title, e, subindex, setmanagementdata, 1)} name='manage' disabled={itm?.rights[1].editable === true ? false : true} /> */}
                                        </Stack>
                                      </Grid>
                                    </Stack>
                                  );
                                })}
                              </Grid>
                              <Grid xs={12}>
                                <Stack
                                  direction="row"
                                  justifyContent="end"
                                  gap={2}
                                  my={2}
                                >
                                  <Button
                                    sx={{
                                      // height:'40px',
                                      padding: "10px 18px",
                                      borderRadius: "8px",
                                      backgroundColor: "#0E5E84",
                                      color: "#FFF",
                                      fontSize: "14px",
                                      fontWeight: 600,
                                      "&:hover": {
                                        backgroundColor: "#022B3E",
                                      },
                                    }}
                                    onClick={() => {
                                      editSave(
                                        index,
                                        editmanagementData,
                                        editManagementExpand
                                      );
                                      seteditedData({
                                        index: index,
                                        state: setEditManagementExpand,
                                      });
                                    }}
                                  >
                                    {LanguageData?.Save || "Save"}
                                  </Button>
                                  <Button
                                    sx={{
                                      width: "85px",
                                      height: "41px",
                                      color: "#0E5E84",
                                      backgroundColor: "#E7EFF3",
                                      "&:hover": {
                                        backgroundColor: "#CFDFE6",
                                      },
                                    }}
                                    onClick={() => {
                                      setDeletingIndex(id);
                                      handleShowModal();
                                    }}
                                  >
                                    {LanguageData?.Delete || "Delete"}
                                  </Button>
                                </Stack>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    );
                  })}
                  {addrole?.management &&
                    commoninputblock(
                      data,
                      SaveRole,
                      handlechange,
                      setaddrole,
                      errortext,
                      setmanagementdata,
                      "management",
                      expand,
                      handleexpand
                    )}
                  {managementdata?.length > 0 && !addrole.management && (
                    <Stack direction="row" justifyContent="center">
                      <Button
                        sx={{
                          border: "1px solid #D2D6DB",
                          width: "fit-content",
                          color: "#0E5E84",
                          backgroundColor: "#FFFFFF",
                          "&:hover": {
                            backgroundColor: "#F3F4F6",
                          },
                        }}
                        onClick={() => {
                          setaddrole({
                            management: true,
                            operation: false,
                            groundstaff: false,
                          });
                          setadd({
                            management: true,
                            operation: false,
                            groundstaff: false,
                          });
                        }}
                      >
                        {LanguageData?.Add_More_Role || "Add More Role"}
                      </Button>
                    </Stack>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid xs={12}>
              <Stack
                sx={{
                  borderRadius: "8px 8px 0 0",
                  borderBottom: "1px solid #E5E7EB",
                }}
                direction="row"
                bgcolor="#FCFCFD"
                alignItems="center"
                padding="16px"
              >
                <Box flexGrow={1}>
                  <Stack direction="row" alignItems="center">
                    <PrimaryTypography>
                      {LanguageData?.Operations || "Operations"}{" "}
                      <span style={{ color: "#9DA4AE", paddingLeft: "7px" }}>
                        {" "}
                        |
                      </span>{" "}
                    </PrimaryTypography>
                    <StyledSecondaryTypography ml={1.2}>
                      {operationdata?.length}{" "}
                      {LanguageData?.Roles_no || "Roles"}
                    </StyledSecondaryTypography>
                  </Stack>
                </Box>
                <SystemDetailsModal commonmodal={commonmodal.operations} />
              </Stack>
              <Box
                component="div"
                padding="24px 10px 24px 10px"
                sx={{
                  backgroundColor: "#FFFFFF !important",
                  borderRadius: "0 0 8px 8px ",
                }}
              >
                <Box
                  component="div"
                  sx={{
                    backgroundColor: "#FFFFFF !important",
                    borderRadius: "12px",
                  }}
                >
                  {!add?.operation && !(operationdata?.length > 0) && (
                    <Box
                      gap="30px"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "1px solid #E5E7EB",
                        borderRadius: "12px",
                        padding: "24px 10px 24px 10px",
                      }}
                    >
                      <OperationIcon />
                      <Box
                        maxWidth="728px"
                        gap="12px"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TypographyDefault
                          sx={{ fontWeight: "600 !important" }}
                        >
                          {LanguageData?.Operations || "Operations"}
                        </TypographyDefault>
                        <TypographyDefault>
                          {LanguageData?.Operations_des ||
                            "Operations department represents the operations team users who require to filter and act on tasks, and assign them to further employees. Examples would include juristics adminstrators who will recieve feedback and calls from tenants, and assign employees like technicians or cleaners to act on it."}
                        </TypographyDefault>
                        <Button
                          variant="outlined"
                          sx={{ border: " 1px solid #D2D6DB" }}
                          onClick={() => addCommonblock("operation")}
                        >
                          {LanguageData?.Add_Role || "Add Role"}
                        </Button>
                      </Box>
                    </Box>
                  )}
                  {editoperationdata?.map((itm, index) => {
                    const { id, department_id } = itm;
                    return (
                      <Box
                        key={index}
                        sx={{
                          borderRadius: "12px",
                          marginBottom: "10px",
                          border: "1px solid #E5E7EB",
                        }}
                      >
                        <Accordion
                          defaultExpanded={false}
                          expanded={
                            Boolean(editOperationExpand[index]?.expand)
                              ? editOperationExpand[index]?.expand
                              : false
                          }
                          onChange={(e, expanded) =>
                            handleEditExpand(
                              e,
                              expanded,
                              index,
                              setEditOperationExpand
                            )
                          }
                          sx={{ background: "none" }}
                        >
                          <AccordionSummary
                            onClick={() => {
                              setgetId([]);
                              seteditoperationdata(operationdata);
                              seterrortext("");
                            }}
                            expandIcon={<ExpandMoreIcon />}
                          >
                            <SecondaryTypography>
                              {itm?.title}
                            </SecondaryTypography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container xs={12} gap={3}>
                              <Grid xs={12}>
                                <StyledTypography>
                                  {LanguageData?.Roles || "Roles"}
                                </StyledTypography>
                                <TextField
                                  name="title"
                                  value={itm?.title}
                                  sx={{
                                    "& .MuiInputBase-root": {
                                      color: errortext ? "#912018" : "#111927",
                                    },
                                    backgroundColor: Boolean(errortext)
                                      ? "#FEE4E2"
                                      : "",
                                  }}
                                  onChange={(e) => {
                                    editData(
                                      index,
                                      "role",
                                      e,
                                      "",
                                      seteditoperationdata
                                    );
                                  }}
                                  inputProps={{ maxLength: 30 }}
                                  InputProps={{
                                    disableUnderline: true,
                                    endAdornment: (
                                      <InputAdornment
                                        sx={{
                                          position: "absolute",
                                          right: 0,
                                          top: "-15px",
                                        }}
                                      >
                                        {itm?.title?.length}/50
                                      </InputAdornment>
                                    ),
                                  }}
                                  fullWidth
                                />
                                <ErrorTypography>{errortext}</ErrorTypography>
                              </Grid>
                              <Grid xs={12}>
                                <Stack direction="row">
                                  <Grid xs={8}>
                                    <TypographyDefault
                                      sx={{ fontWeight: "600 !important" }}
                                    >
                                      {LanguageData?.Functions || "Functions"}
                                    </TypographyDefault>
                                  </Grid>
                                  <Grid xs={4}>
                                    <Stack
                                      direction="row"
                                      justifyContent="space-evenly"
                                      alignItems="center"
                                    >
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        gap="5px"
                                      >
                                        <TypographyDefault
                                          ml={1}
                                          sx={{ fontWeight: "600 !important" }}
                                        >
                                          {LanguageData?.Access || "Access"}
                                        </TypographyDefault>
                                        <SystemDetailsModal
                                          commonmodal={commonmodal.access}
                                        />
                                      </Stack>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        gap="5px"
                                      >
                                        <TypographyDefault
                                          sx={{ fontWeight: "600 !important" }}
                                        >
                                          {LanguageData?.Manage || "Manage"}
                                        </TypographyDefault>
                                        <SystemDetailsModal
                                          commonmodal={commonmodal.manage}
                                        />
                                      </Stack>
                                    </Stack>
                                  </Grid>
                                </Stack>
                              </Grid>
                              <Grid xs={12}>
                                {itm?.service?.map((sitm, subindex) => {
                                  return (
                                    <Stack
                                      key={subindex}
                                      direction="row"
                                      alignItems="center"
                                    >
                                      <Grid xs={8}>
                                        <Stack my={2} gap="5px">
                                          <TypographyDefault>
                                            {sitm?.service_name}
                                          </TypographyDefault>
                                          <StyledSecondaryTypography
                                            sx={{ fontSize: "12px !important" }}
                                          >
                                            {sitm?.description}
                                          </StyledSecondaryTypography>
                                        </Stack>
                                      </Grid>
                                      <Grid xs={4}>
                                        <Stack
                                          my={2}
                                          direction="row"
                                          justifyContent="space-evenly"
                                          alignItems="center"
                                        >
                                          {sitm.rights.map(
                                            (rtsitm, ssindex) => {
                                              return (
                                                <Checkbox
                                                  checked={rtsitm?.enabled}
                                                  icon={
                                                    <CheckboxIcon
                                                      fill={
                                                        rtsitm?.editable ===
                                                        true
                                                          ? "none"
                                                          : "#D2D6DB"
                                                      }
                                                    />
                                                  }
                                                  onChange={(e) =>
                                                    editData(
                                                      index,
                                                      sitm.title,
                                                      e,
                                                      subindex,
                                                      seteditoperationdata,
                                                      editoperationdata,
                                                      ssindex
                                                    )
                                                  }
                                                  name="access"
                                                  disabled={
                                                    rtsitm?.editable === true
                                                      ? false
                                                      : true
                                                  }
                                                />
                                              );
                                            }
                                          )}
                                          {/* <Checkbox checked={itm.rights[0].enabled} icon={<CheckboxIcon />} onChange={(e) => editData(index, itm.title, e, subindex, setoperationdata, 0)} name='access' disabled={itm?.rights[0].editable === true ? false : true} />
                                                                            <Checkbox checked={itm.rights[1].enabled} icon={<CheckboxIcon />} onChange={(e) => editData(index, itm.title, e, subindex, setoperationdata, 1)} name='manage' disabled={itm?.rights[1].editable === true ? false : true} /> */}
                                        </Stack>
                                      </Grid>
                                    </Stack>
                                  );
                                })}
                              </Grid>
                              <Grid xs={12}>
                                <Stack
                                  direction="row"
                                  justifyContent="end"
                                  gap={2}
                                  my={2}
                                >
                                  <Button
                                    sx={{
                                      padding: "10px 18px",
                                      borderRadius: "8px",
                                      backgroundColor: "#0E5E84",
                                      color: "#FFF",
                                      fontSize: "14px",
                                      fontWeight: 600,
                                      "&:hover": {
                                        backgroundColor: "#022B3E",
                                      },
                                    }}
                                    onClick={() => {
                                      editSave(index, editoperationdata);
                                      seteditedData({
                                        index: index,
                                        state: setEditOperationExpand,
                                      });
                                    }}
                                  >
                                    {LanguageData?.Save || "Save"}
                                  </Button>
                                  <Button
                                    sx={{
                                      width: "85px",
                                      height: "41px",
                                      color: "#0E5E84",
                                      backgroundColor: "#E7EFF3",
                                      "&:hover": {
                                        backgroundColor: "#CFDFE6",
                                      },
                                    }}
                                    onClick={() => {
                                      setDeletingIndex(id);
                                      handleShowModal();
                                    }}
                                  >
                                    {LanguageData?.Delete || "Delete"}
                                  </Button>
                                </Stack>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    );
                  })}
                  {addrole?.operation &&
                    commoninputblock(
                      data,
                      SaveRole,
                      handlechange,
                      setaddrole,
                      errortext,
                      setoperationdata,
                      "operation",
                      expand,
                      handleexpand
                    )}
                  {operationdata?.length > 0 && !addrole.operation && (
                    <Stack direction="row" justifyContent="center">
                      <Button
                        sx={{
                          border: "1px solid #D2D6DB",
                          width: "fit-content",
                          color: "#0E5E84",
                          backgroundColor: "#FFFFFF",
                          "&:hover": {
                            backgroundColor: "#F3F4F6",
                          },
                        }}
                        onClick={() => {
                          setaddrole({
                            management: false,
                            operation: true,
                            groundstaff: false,
                          });
                          setadd({
                            management: false,
                            operation: true,
                            groundstaff: false,
                          });
                        }}
                      >
                        {LanguageData?.Add_More_Role || "Add More Role"}
                      </Button>
                    </Stack>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid xs={12}>
              <Stack
                sx={{
                  borderRadius: "8px 8px 0 0",
                  borderBottom: "1px solid #E5E7EB",
                }}
                direction="row"
                bgcolor="#FCFCFD"
                alignItems="center"
                padding="16px"
              >
                <Box flexGrow={1}>
                  <Stack direction="row" alignItems="center">
                    <PrimaryTypography>
                      {LanguageData?.Ground_Staff || "Ground Staff"}{" "}
                      <span style={{ color: "#9DA4AE", paddingLeft: "7px" }}>
                        {" "}
                        |
                      </span>{" "}
                    </PrimaryTypography>
                    <StyledSecondaryTypography ml={1.2}>
                      {groundstaffdata?.length}{" "}
                      {LanguageData?.Roles_no || "Roles"}
                    </StyledSecondaryTypography>
                  </Stack>
                </Box>
                <SystemDetailsModal commonmodal={commonmodal.groundstaff} />
              </Stack>
              <Box
                component="div"
                padding="24px 10px 24px 10px"
                sx={{
                  backgroundColor: "#FFFFFF !important",
                  borderRadius: "0 0 8px 8px",
                }}
              >
                <Box
                  component="div"
                  sx={{
                    backgroundColor: "#FFFFFF !important",
                    borderRadius: "12px",
                  }}
                >
                  {!add?.groundstaff && !(groundstaffdata?.length > 0) && (
                    <Box
                      gap="30px"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "1px solid #E5E7EB",
                        borderRadius: "12px",
                        padding: "24px 10px 24px 10px",
                      }}
                    >
                      <GroundStaffIcon />
                      <Box
                        maxWidth="728px"
                        gap="12px"
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <TypographyDefault
                          sx={{ fontWeight: "600 !important" }}
                        >
                          {LanguageData?.Ground_Staff || "Ground Staff"}
                        </TypographyDefault>
                        <TypographyDefault>
                          {LanguageData?.Ground_des ||
                            "Ground Staff represents the team or users who like act on assigned tasks. This will include cleaning department, fixing and plumbing department and can include both supervisors and junior staff of cleaners,technicians, security who are in charge of acting upon feedback and ensuring residents safety & comfort."}
                        </TypographyDefault>
                        <Button
                          variant="outlined"
                          sx={{ border: " 1px solid #D2D6DB" }}
                          onClick={() => addCommonblock("groundstaff")}
                        >
                          {LanguageData?.Add_Role || "Add Role"}
                        </Button>
                      </Box>
                    </Box>
                  )}

                  {editgroundstaffdata?.map((itm, index) => {
                    const { id, department_id } = itm;
                    return (
                      <Box
                        key={index}
                        sx={{
                          borderRadius: "12px",
                          marginBottom: "10px",
                          border: "1px solid #E5E7EB",
                        }}
                      >
                        <Accordion
                          defaultExpanded={false}
                          expanded={
                            Boolean(editGroundStaffExpand[index]?.expand)
                              ? editGroundStaffExpand[index]?.expand
                              : false
                          }
                          onChange={(e, expanded) =>
                            handleEditExpand(
                              e,
                              expanded,
                              index,
                              setEditGroundStaffExpand
                            )
                          }
                          sx={{ background: "none" }}
                        >
                          <AccordionSummary
                            onClick={() => {
                              setgetId([]);
                              seteditgroundstaffdata(groundstaffdata);
                              seterrortext("");
                            }}
                            expandIcon={<ExpandMoreIcon />}
                          >
                            <SecondaryTypography>
                              {itm?.title}
                            </SecondaryTypography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container xs={12} gap={3}>
                              <Grid xs={12}>
                                <StyledTypography>
                                  {LanguageData?.Roles || "Roles"}
                                </StyledTypography>
                                <TextField
                                  name="title"
                                  value={itm?.title}
                                  onChange={(e) => {
                                    editData(
                                      index,
                                      "role",
                                      e,
                                      "",
                                      seteditgroundstaffdata,
                                      editgroundstaffdata,
                                      ""
                                    );
                                  }}
                                  inputProps={{ maxLength: 30 }}
                                  InputProps={{
                                    disableUnderline: true,
                                    endAdornment: (
                                      <InputAdornment
                                        sx={{
                                          position: "absolute",
                                          right: 0,
                                          top: "-15px",
                                        }}
                                      >
                                        {itm?.title?.length}/50
                                      </InputAdornment>
                                    ),
                                  }}
                                  fullWidth
                                  sx={{
                                    "& .MuiInputBase-root": {
                                      color: errortext ? "#912018" : "#111927",
                                    },
                                    backgroundColor: Boolean(errortext)
                                      ? "#FEE4E2"
                                      : "",
                                  }}
                                />
                                <ErrorTypography>{errortext}</ErrorTypography>
                              </Grid>
                              <Grid xs={12}>
                                <Stack direction="row">
                                  <Grid xs={8}>
                                    <TypographyDefault
                                      sx={{ fontWeight: "600 !important" }}
                                    >
                                      {LanguageData?.Functions || "Functions"}
                                    </TypographyDefault>
                                  </Grid>
                                  <Grid xs={4}>
                                    <Stack
                                      direction="row"
                                      justifyContent="space-evenly"
                                      alignItems="center"
                                    >
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        gap="5px"
                                      >
                                        <TypographyDefault
                                          ml={1}
                                          sx={{ fontWeight: "600 !important" }}
                                        >
                                          {LanguageData?.Access || "Access"}
                                        </TypographyDefault>
                                        <SystemDetailsModal
                                          commonmodal={commonmodal.access}
                                        />
                                      </Stack>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        gap="5px"
                                      >
                                        <TypographyDefault
                                          sx={{ fontWeight: "600 !important" }}
                                        >
                                          {LanguageData?.Manage || "Manage"}
                                        </TypographyDefault>
                                        <SystemDetailsModal
                                          commonmodal={commonmodal.manage}
                                        />
                                      </Stack>
                                    </Stack>
                                  </Grid>
                                </Stack>
                              </Grid>
                              <Grid xs={12}>
                                {itm?.service?.map((sitm, subindex) => {
                                  return (
                                    <Stack
                                      key={subindex}
                                      direction="row"
                                      alignItems="center"
                                    >
                                      <Grid xs={8}>
                                        <Stack my={2} gap="5px">
                                          <TypographyDefault>
                                            {sitm.service_name}
                                          </TypographyDefault>
                                          <StyledSecondaryTypography
                                            sx={{ fontSize: "12px !important" }}
                                          >
                                            {sitm.description}
                                          </StyledSecondaryTypography>
                                        </Stack>
                                      </Grid>
                                      <Grid xs={4}>
                                        <Stack
                                          my={2}
                                          direction="row"
                                          justifyContent="space-evenly"
                                          alignItems="center"
                                        >
                                          {sitm.rights.map(
                                            (rtsitm, ssindex) => {
                                              return (
                                                <Checkbox
                                                  checked={
                                                    rtsitm?.enabled === true
                                                      ? true
                                                      : false
                                                  }
                                                  icon={
                                                    <CheckboxIcon
                                                      fill={
                                                        rtsitm?.editable ===
                                                        true
                                                          ? "none"
                                                          : "#D2D6DB"
                                                      }
                                                    />
                                                  }
                                                  onChange={(e) =>
                                                    editData(
                                                      index,
                                                      sitm.title,
                                                      e,
                                                      subindex,
                                                      seteditgroundstaffdata,
                                                      editgroundstaffdata,
                                                      ssindex
                                                    )
                                                  }
                                                  name="access"
                                                  disabled={
                                                    rtsitm?.editable === true
                                                      ? false
                                                      : true
                                                  }
                                                />
                                              );
                                            }
                                          )}
                                          {/* <Checkbox checked={itm.rights[0].enabled} icon={<CheckboxIcon />} onChange={(e) => editData(index, itm.title, e, subindex, setgroundstaffdata, 0)} name='access' disabled={itm?.rights[0].editable === true ? false : true} />
                                                                            <Checkbox checked={itm.rights[1].enabled} icon={<CheckboxIcon />} onChange={(e) => editData(index, itm.title, e, subindex, setgroundstaffdata, 1)} name='manage' disabled={itm?.rights[1].editable === true ? false : true} /> */}
                                        </Stack>
                                      </Grid>
                                    </Stack>
                                  );
                                })}
                              </Grid>
                              <Grid xs={12}>
                                <Stack
                                  direction="row"
                                  justifyContent="end"
                                  gap={2}
                                  my={2}
                                >
                                  <Button
                                    sx={{
                                      // height: "40px",
                                      padding: "10px 18px",
                                      borderRadius: "8px",
                                      backgroundColor: "#0E5E84",
                                      color: "#FFF",
                                      fontSize: "14px",
                                      fontWeight: 600,
                                      "&:hover": {
                                        backgroundColor: "#022B3E",
                                      },
                                    }}
                                    onClick={() => {
                                      editSave(index, editgroundstaffdata);
                                      seteditedData({
                                        index: index,
                                        state: setEditGroundStaffExpand,
                                      });
                                    }}
                                  >
                                    {LanguageData?.Save || "Save"}
                                  </Button>
                                  <Button
                                    sx={{
                                      width: "85px",
                                      height: "41px",
                                      color: "#0E5E84",
                                      backgroundColor: "#E7EFF3",
                                      "&:hover": {
                                        backgroundColor: "#CFDFE6",
                                      },
                                    }}
                                    onClick={() => {
                                      setDeletingIndex(id);
                                      handleShowModal();
                                    }}
                                  >
                                    {LanguageData?.Delete || "Delete"}
                                  </Button>
                                </Stack>
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    );
                  })}

                  {addrole?.groundstaff &&
                    commoninputblock(
                      data,
                      SaveRole,
                      handlechange,
                      setaddrole,
                      errortext,
                      setgroundstaffdata,
                      "groundstaff",
                      expand,
                      handleexpand
                    )}

                  {groundstaffdata?.length > 0 && !addrole.groundstaff && (
                    <Stack direction="row" justifyContent="center">
                      <Button
                        sx={{
                          border: "1px solid #D2D6DB",
                          width: "fit-content",
                          color: "#0E5E84",
                          backgroundColor: "#FFFFFF",
                          "&:hover": {
                            backgroundColor: "#F3F4F6",
                          },
                        }}
                        onClick={() => {
                          setaddrole({
                            management: false,
                            operation: false,
                            groundstaff: true,
                          });
                          setadd({
                            management: false,
                            operation: false,
                            groundstaff: true,
                          });
                        }}
                      >
                        {LanguageData?.Add_More_Role || "Add More Role"}
                      </Button>
                    </Stack>
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid xs={12}>
              <Box bgcolor="#FFFFFF" sx={{ borderRadius: "8px" }}>
                <Stack
                  direction="row"
                  py={2}
                  px={2}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction="row">
                    <RefreshIcon style={{ marginRight: "5px" }} />
                    <StyledSecondaryTypography>
                      {LanguageData?.Common_last_Update || "Last Updated at"}{" "}
                      {moment(new Date()).format("HH:mm A")}
                    </StyledSecondaryTypography>
                  </Stack>
                  <Stack
                    direction="column"
                    justifyContent="end"
                    alignItems="end"
                  >
                    <Button
                      sx={{
                        width: "fit-content",
                        backgroundColor: "#E7EFF3",
                        "&:hover": {
                          backgroundColor: "#CFDFE6",
                        },
                      }}
                      onClick={() => Continue("Review Information")}
                    >
                      {LanguageData?.Common_Continue || "Continue"}
                    </Button>
                    {contineerr && (
                      <ErrorTypography>
                        {LanguageData?.Min ||
                          "Min 1 role should be added in every department"}
                      </ErrorTypography>
                    )}
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };

  const Review_Information = () => {
    return (
      <>
        <Box sx={{ borderRadius: "0 0 8px 8px" }}>
          <Grid container gap="30px">
            <Grid xs={12}>
              <Stack
                sx={{
                  borderRadius: "8px 8px 0 0 ",
                  borderBottom: "1px solid #E5E7EB",
                }}
                direction="row"
                bgcolor="#FCFCFD"
                alignItems="center"
                padding="16px"
              >
                <Box flexGrow={1}>
                  <Stack direction="row" alignItems="center">
                    <PrimaryTypography>
                      {LanguageData?.home_tab_management || "Management"}{" "}
                    </PrimaryTypography>
                    <StyledSecondaryTypography ml="20px">
                      {managementdata?.length}{" "}
                      {LanguageData?.Roles_no || "Roles"}
                    </StyledSecondaryTypography>
                  </Stack>
                </Box>
                <SystemDetailsModal commonmodal={commonmodal.management} />
              </Stack>
              <Box
                component="div"
                padding={1}
                sx={{
                  backgroundColor: "#FFFFFF !important",
                  borderRadius: "0 0 8px 8px",
                }}
              >
                <Box sx={{ marginBottom: "20px" }}>
                  {managementdata?.map((itm, index) => {
                    const { title, service } = itm;
                    return (
                      <Accordion
                        sx={{
                          background: "none",
                          border: "none",
                          borderBottom:
                            managementdata?.length !== index + 1 &&
                            "1px solid #F3F4F6",
                          "::before": { height: "0px" },
                        }}
                      >
                        <AccordionSummary
                          height="55px"
                          expandIcon={<ExpandMoreIcon />}
                        >
                          <SecondaryTypography>{title}</SecondaryTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container xs={12} gap={3}>
                            <Grid xs={12}>
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                              >
                                {itm?.service[0].rights.map((rts) => {
                                  return (
                                    <Grid xs={6}>
                                      <StyledSecondaryTypography>
                                        {rts.name}
                                      </StyledSecondaryTypography>
                                    </Grid>
                                  );
                                })}
                              </Stack>
                            </Grid>
                          </Grid>
                          <Stack direction="row">
                            <Stack direction="column" width="50%">
                              {itm?.service.map((sitm, sindex) => {
                                return (
                                  <>
                                    {sitm.rights[0].enabled === true &&
                                      sitm.rights[0].editable === true && (
                                        <Stack mt={1} gap="2px">
                                          <TypographyDefault>
                                            {sitm.service_name}
                                          </TypographyDefault>
                                        </Stack>
                                      )}
                                  </>
                                );
                              })}
                            </Stack>
                            <Stack direction="column" width="50%">
                              {itm?.service.map((sitm, sindex) => {
                                return (
                                  <>
                                    {sitm.rights[1].enabled === true &&
                                      sitm.rights[1].editable === true && (
                                        <Stack mt={1} gap="2px">
                                          <TypographyDefault>
                                            {sitm.service_name}
                                          </TypographyDefault>
                                        </Stack>
                                      )}
                                  </>
                                );
                              })}
                            </Stack>
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </Box>
              </Box>
            </Grid>
            <Grid xs={12}>
              <Stack
                sx={{
                  borderRadius: "8px 8px 0 0 ",
                  borderBottom: "1px solid #E5E7EB",
                }}
                direction="row"
                bgcolor="#FCFCFD"
                alignItems="center"
                padding="16px"
              >
                <Box flexGrow={1}>
                  <Stack direction="row" alignItems="center">
                    <PrimaryTypography>
                      {LanguageData?.Operations || "Operations"}
                    </PrimaryTypography>
                    <StyledSecondaryTypography ml="20px">
                      {operationdata?.length}{" "}
                      {LanguageData?.Roles_no || "Roles"}
                    </StyledSecondaryTypography>
                  </Stack>
                </Box>
                <SystemDetailsModal commonmodal={commonmodal.operations} />
              </Stack>
              <Box
                component="div"
                padding={1}
                sx={{
                  backgroundColor: "#FFFFFF !important",
                  borderRadius: "0 0 8px 8px",
                }}
              >
                <Box sx={{ marginBottom: "20px" }}>
                  {operationdata?.map((itm, index) => {
                    const { title, info } = itm;
                    return (
                      <Accordion
                        sx={{
                          background: "none",
                          border: "none",
                          borderBottom:
                            operationdata?.length !== index + 1 &&
                            "1px solid #F3F4F6",
                          "::before": { height: "0px" },
                        }}
                      >
                        <AccordionSummary
                          height="55px"
                          expandIcon={<ExpandMoreIcon />}
                        >
                          <SecondaryTypography>{title}</SecondaryTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container xs={12} gap={3}>
                            <Grid xs={12}>
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                              >
                                {itm?.service[0].rights.map((rts) => {
                                  return (
                                    <Grid xs={6}>
                                      <StyledSecondaryTypography>
                                        {rts.name}
                                      </StyledSecondaryTypography>
                                    </Grid>
                                  );
                                })}
                              </Stack>
                            </Grid>
                          </Grid>
                          <Stack direction="row">
                            <Stack direction="column" width="50%">
                              {itm?.service.map((sitm, sindex) => {
                                return (
                                  <>
                                    {sitm.rights[0].enabled === true &&
                                      sitm.rights[0].editable === true && (
                                        <Stack mt={1} gap="2px">
                                          <TypographyDefault>
                                            {sitm.service_name}
                                          </TypographyDefault>
                                        </Stack>
                                      )}
                                  </>
                                );
                              })}
                            </Stack>
                            <Stack direction="column" width="50%">
                              {itm?.service.map((sitm, sindex) => {
                                return (
                                  <>
                                    {sitm.rights[1].enabled === true &&
                                      sitm.rights[1].editable === true && (
                                        <Stack mt={1} gap="2px">
                                          <TypographyDefault>
                                            {sitm.service_name}
                                          </TypographyDefault>
                                        </Stack>
                                      )}
                                  </>
                                );
                              })}
                            </Stack>
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </Box>
              </Box>
            </Grid>
            <Grid xs={12}>
              <Stack
                sx={{
                  borderRadius: "8px 8px 0 0 ",
                  borderBottom: "1px solid #E5E7EB",
                }}
                direction="row"
                bgcolor="#FCFCFD"
                alignItems="center"
                padding="16px"
              >
                <Box flexGrow={1}>
                  <Stack direction="row" alignItems="center">
                    <PrimaryTypography>
                      {LanguageData?.Ground_Staff || "Ground Staff"}
                    </PrimaryTypography>
                    <StyledSecondaryTypography ml="20px">
                      {groundstaffdata?.length}{" "}
                      {LanguageData?.Roles_no || "Roles"}
                    </StyledSecondaryTypography>
                  </Stack>
                </Box>
                <SystemDetailsModal commonmodal={commonmodal.groundstaff} />
              </Stack>
              <Box
                component="div"
                padding={1}
                sx={{
                  backgroundColor: "#FFFFFF !important",
                  borderRadius: "0 0 8px 8px",
                }}
              >
                <Box sx={{ marginBottom: "20px" }}>
                  {groundstaffdata?.map((itm, index) => {
                    const { title, info } = itm;
                    return (
                      <Accordion
                        sx={{
                          background: "none",
                          border: "none",
                          borderBottom:
                            groundstaffdata?.length !== index + 1 &&
                            "1px solid #F3F4F6",
                          "::before": { height: "0px" },
                        }}
                      >
                        <AccordionSummary
                          height="55px"
                          expandIcon={<ExpandMoreIcon />}
                        >
                          <SecondaryTypography>{title}</SecondaryTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container xs={12} gap={3}>
                            <Grid xs={12}>
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                              >
                                {itm?.service[0].rights.map((rts) => {
                                  return (
                                    <Grid xs={6}>
                                      <StyledSecondaryTypography>
                                        {rts.name}
                                      </StyledSecondaryTypography>
                                    </Grid>
                                  );
                                })}
                              </Stack>
                            </Grid>
                          </Grid>
                          <Stack direction="row">
                            <Stack direction="column" width="50%">
                              {itm?.service.map((sitm, sindex) => {
                                return (
                                  <>
                                    {sitm.rights[0].enabled === true &&
                                      sitm.rights[0].editable === true && (
                                        <Stack mt={1} gap="2px">
                                          <TypographyDefault>
                                            {sitm.service_name}
                                          </TypographyDefault>
                                        </Stack>
                                      )}
                                  </>
                                );
                              })}
                            </Stack>
                            <Stack direction="column" width="50%">
                              {itm?.service.map((sitm, sindex) => {
                                return (
                                  <>
                                    {sitm.rights[1].enabled === true &&
                                      sitm.rights[1].editable === true && (
                                        <Stack mt={1} gap="2px">
                                          <TypographyDefault>
                                            {sitm.service_name}
                                          </TypographyDefault>
                                        </Stack>
                                      )}
                                  </>
                                );
                              })}
                            </Stack>
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </Box>
              </Box>
            </Grid>
            <Grid xs={12}>
              <Box bgcolor="#FFFFFF" sx={{ borderRadius: "8px" }}>
                <Stack
                  direction="row"
                  py={2}
                  px={2}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack direction="row">
                    <RefreshIcon style={{ marginRight: "5px" }} />
                    <StyledSecondaryTypography>
                      {LanguageData?.Common_last_Update || "Last Updated at"}{" "}
                      {moment(new Date()).format("HH:mm A")}
                    </StyledSecondaryTypography>
                  </Stack>
                  <Stack
                    direction="column"
                    justifyContent="end"
                    alignItems="end"
                  >
                    <Button
                      sx={{
                        width: "fit-content",
                        backgroundColor: "#E7EFF3",
                        "&:hover": {
                          backgroundColor: "#CFDFE6",
                        },
                      }}
                      onClick={handleContinue}
                    >
                      {LanguageData?.Confirm || "Confirm"}
                    </Button>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}
      <Box sx={{ backgroundColor: "#F3F4F6" }}>
        <Stack direction="row" justifyContent="space-between">
          <BackArrow
            onClick={() => {
              navigate("/settings");
            }}
          />
          {/* <BorderAlertCloseIcon width='40' height='40' /> */}
        </Stack>
        <Stack
          my={4}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <PrimaryTypography mb={0.5}>
              {LanguageData?.User || "User"}
            </PrimaryTypography>
            <StyledSecondaryTypography>
              {LanguageData?.Role_Des ||
                "Edit or add departments and roles. Also, changing the permission for each roles."}
            </StyledSecondaryTypography>
          </Box>
        </Stack>
        <Grid container gap="1.5%" mb={5}>
          {blocks.map((itm) => {
            return (
              <Grid xs={5.91} onClick={() => Continue(itm.tle, "")}>
                <Box
                  component="div"
                  sx={{
                    borderTop: itm.status
                      ? "3px solid #0E4965"
                      : "3px solid #D2D6DB",
                    width: "100%",
                    color: itm.status ? "#0E4965" : "#9DA4AE",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "500",
                      fontSize: "12px",
                      padding: "10px 0 0 0",
                    }}
                  >
                    {itm.tle}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>

        {addblocks.Details && Details()}
        {addblocks.Review_Information && Review_Information()}
      </Box>
    </>
  );
};

export default EditUserRole;
