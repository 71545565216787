import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  TextField,
  Typography,
  Menu,
  CircularProgress,
} from "@mui/material";
import {
  PrimaryTypography,
  StyledTypography,
  TypographyDefault,
  SecondaryTypographyDefault,
} from "../../../Theme";
import { ReactComponent as BookingDropDown } from "../../../Icons/DeviceDropArrow.svg";
import { ReactComponent as SideArrow } from "../../../Icons/SideDropDownArrow.svg";
import { ReactComponent as BackArrow } from "../../../Icons/BorderBackArrow.svg";
import axiosInstance from "../../../Redux/AxiosInstance";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PostLoaderModal } from "../../../CommonComponents/modal";
import { showToast } from "../../../CommonComponents/Toaster";

const AddDevice = () => {
  const [area, setArea] = useState(null);
  const navigate = useNavigate();
  const areaOpen = Boolean(area);
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedFacilityId, setSelectedFacilityId] = useState(0);
  const [selectedFacility, setSelectedFacility] = useState("");
  const [selectedAccess, setSelectedAccess] = useState("");
  const [selectedAccessId, setSelectedAccessId] = useState(0);
  const [BuildingData, setBuildingData] = useState([]);
  const [CommonAreaData, setCommonAreaData] = useState([]);
  const [FacilityData, setFacilityData] = useState([]);
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { LanguageData } = useSelector((state) => state.Language);
  const [Facility, setFacility] = useState(null);
  const facilityOpen = Boolean(Facility);
  const [CommonArea, setCommonArea] = useState(null);
  const commonOpen = Boolean(CommonArea);
  const [Building, setBuilding] = useState(null);
  const buildingOpen = Boolean(Building);
  const [access, SetAccess] = useState(null);
  const accessOpen = Boolean(access);
  const [deviceName, setDeviceName] = useState("");
  const [errtext, setErrText] = useState(false);
  const [areaError, setAreaError] = useState(false);
  const [accessError, setAccessError] = useState(false);
  const [postLoader, SetPostLoader] = useState(false);
  const [facilityLoader, setFacilityLoader] = useState(true);

  const handleFacility = (e) => {
    setFacility(e.currentTarget);
  };
  const handleCommon = (e) => {
    setCommonArea(e.currentTarget);
  };
  const handleBuilding = (e) => {
    setBuilding(e.currentTarget);
  };

  const handleAccess = (value) => {
    if (value) {
      setSelectedAccess(value);
      SetAccess(null);
      setAccessError(false);
    }
    if (value === "In") {
      setSelectedAccessId(1);
    } else if (value === "Out") {
      setSelectedAccessId(2);
    } else {
      setSelectedAccessId(3);
    }
  };

  const handleSelectedFacility = (id, facilityselect, value) => {
    setSelectedFacilityId(id);
    setSelectedFacility(facilityselect);
    setSelectedArea(value);
    setArea(null);
    setFacility(null);
    setAreaError(false);
  };

  const handleSelectedBuilding = (id, facilityselect, value) => {
    setSelectedFacilityId(id);
    setSelectedFacility(facilityselect);
    setSelectedArea(value);
    setArea(null);
    setBuilding(null);
    setAreaError(false);
  };

  const handleSelectedCommonArea = (id, facilityselect, value) => {
    setSelectedFacilityId(id);
    setSelectedFacility(facilityselect);
    setSelectedArea(value);
    setArea(null);
    setCommonArea(null);
    setAreaError(false);
  };

  useEffect(() => {
    FetchBuildingData();
    FetchCommonAreaData();
    FetchFacilityData();
  }, []);

  const FetchFacilityData = () => {
    setFacilityLoader(true);
    axiosInstance
      .get(`${process.env.REACT_APP_API_URL}facility/property/${propertyId}`)
      .then((res) => {
        setFacilityData(res.data?.data?.facility);
        setFacilityLoader(false);
      })
      .catch((err) => {
        if (err?.response?.data?.error === "No data found.") {
          setFacilityLoader(false);
          setFacilityData([]);
        } else {
          setFacilityLoader(true);
        }
      });
  };

  const FetchBuildingData = () => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_URL}property/${propertyId}/building`)
      .then((res) => {
        setBuildingData(res.data?.data?.property_buildings);
      })
      .catch((err) => console.error(err));
  };

  const FetchCommonAreaData = () => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_URL}property/${propertyId}/commonarea`)
      .then((res) => {
        setCommonAreaData(res.data?.data?.common_areas);
      })
      .catch((err) => console.error(err));
  };

  const handleSubmit = () => {
    if (deviceName.trim() === "") {
      setErrText(true);
    }
    if (!selectedAccessId) {
      setAccessError(true);
    }
    if (!selectedFacilityId) {
      setAreaError(true);
    }
    if (deviceName.trim() && selectedAccessId && selectedFacilityId) {
      SetPostLoader(true);
      const data = new FormData();
      data.append("device_name", deviceName);
      data.append("access", selectedAccessId);
      if (selectedArea === "Facility") {
        data.append("facility_id", selectedFacilityId);
      } else if (selectedArea === "Building") {
        data.append("building_id", selectedFacilityId);
      } else {
        data.append("common_area_id", selectedFacilityId);
      }
      axiosInstance
        .post(
          `${process.env.REACT_APP_API_URL}property/${propertyId}/device`,
          data
        )
        .then((res) => {
          if (res.data?.success) {
            SetPostLoader(false);
            showToast(res.data?.toast);
            navigate("/device-control");
          }
        })
        .catch(() => {
          showToast("Something went wrong. Please try again later", "error");
          SetPostLoader(false);
        });
    }
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}
      <Box sx={{ padding: "0 20px", maxWidth: "728px", margin: "0 auto" }}>
        <BackArrow
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/device-control")}
        />
        <PrimaryTypography sx={{ marginTop: "10px" }}>
          {LanguageData?.Add_Device || "Add Device"}{" "}
        </PrimaryTypography>
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "6px",
            marginTop: "10px",
          }}
        >
          <Box sx={{ padding: "15px 20px 15px 20px" }}>
            <StyledTypography>
              {" "}
              {LanguageData?.Device_name || "Device Name"}{" "}
            </StyledTypography>
            <TextField
              name="device_name"
              inputProps={{ maxLength: 20 }}
              InputProps={{ disableUnderline: true }}
              fullWidth
              placeholder="Enter device name"
              onChange={(e) => {
                setDeviceName(e.target.value);
                setErrText(false);
              }}
              sx={{
                backgroundColor: errtext ? "#FEE4E2" : "F9FAFB",
              }}
            />
          </Box>
          <Box sx={{ padding: "15px 20px 15px 20px" }}>
            <StyledTypography> {LanguageData?.Area || "Area"}</StyledTypography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                borderRadius: "8px",
                padding: "15px 0px 15px 0px",
                cursor: "pointer",
                justifyContent: "space-between",
                marginTop: "10px",
                alignItems: "center",
                backgroundColor: areaError ? "#FEE4E2" : "#F9FAFB",
              }}
              onClick={(e) => setArea(e.currentTarget)}
            >
              {selectedArea && selectedFacility ? (
                <TypographyDefault
                  style={{ padding: "0px 0px 0px 10px", fontSize: "16px" }}
                >
                  {`${selectedArea}${" "}/${" "} ${selectedFacility}`}
                </TypographyDefault>
              ) : (
                <Typography
                  style={{ padding: "0px 0px 0px 10px", color: "#9DA4AE" }}
                >
                  {LanguageData?.Select_the_area || "Select the area"}
                </Typography>
              )}

              <BookingDropDown style={{ padding: "0px 10px 0px 0px" }} />
            </Box>
          </Box>
          <Box sx={{ padding: "15px 20px 15px 20px" }}>
            <StyledTypography>
              {" "}
              {LanguageData?.Access || "Access"}{" "}
            </StyledTypography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                borderRadius: "8px",
                padding: "15px 0px 15px 0px",
                cursor: "pointer",
                justifyContent: "space-between",
                marginTop: "10px",
                alignItems: "center",
                backgroundColor: accessError ? "#FEE4E2" : "#F9FAFB",
              }}
              onClick={(e) => SetAccess(e.currentTarget)}
            >
              {selectedAccess ? (
                <TypographyDefault
                  style={{ padding: "0px 0px 0px 10px", fontSize: "16px" }}
                >
                  {selectedAccess}
                </TypographyDefault>
              ) : (
                <Typography
                  style={{ padding: "0px 0px 0px 10px", color: "#9DA4AE" }}
                >
                  {LanguageData?.Select_the_access || "Select the access"}
                </Typography>
              )}
              <BookingDropDown style={{ padding: "0px 10px 0px 0px" }} />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Button
              style={{
                padding: "10px 16px",
                borderRadius: "8px",
                backgroundColor: "#0E5E84",
                color: "#FFF",
                fontSize: "14px",
                fontWeight: 600,
              }}
              sx={{
                width: "222px",
                marginTop: "20px",
                marginBottom: "20px",
                // padding: "20px",
                "&:hover": {
                  backgroundColor: "#022B3E",
                },
              }}
              onClick={handleSubmit}
            >
              {LanguageData?.Submit || "Submit"}
            </Button>
          </Box>
        </Box>
        <Menu
          sx={{ borderRadius: "8px", border: "1px solid #EAECF0" }}
          open={areaOpen}
          anchorEl={area}
          onClose={() => setArea(null)}
        >
          <MenuItem
            sx={{
              width: "600px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
            onClick={handleFacility}
          >
            <StyledTypography style={{ color: "#344054" }}>
              {LanguageData?.Facility || "Facility"}
            </StyledTypography>
            <SideArrow />
          </MenuItem>
          <MenuItem
            sx={{
              width: "600px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
            onClick={handleCommon}
          >
            <StyledTypography style={{ color: "#344054" }}>
              {LanguageData?.Common_Area || "Common Area"}
            </StyledTypography>
            <SideArrow />
          </MenuItem>
          <MenuItem
            sx={{
              width: "600px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
            onClick={handleBuilding}
          >
            <StyledTypography style={{ color: "#344054" }}>
              {LanguageData?.Building || "Building"}
            </StyledTypography>
            <SideArrow />
          </MenuItem>
        </Menu>

        <Menu
          sx={{ borderRadius: "8px", border: "1px solid #EAECF0" }}
          open={facilityOpen}
          anchorEl={Facility}
          onClose={() => setFacility(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          {!facilityLoader && FacilityData.length > 0 ? (
            FacilityData.map((value) => (
              <MenuItem
                onClick={() =>
                  handleSelectedFacility(value?.id, value?.title, "Facility")
                }
                key={value?.id}
              >
                {value?.title}
              </MenuItem>
            ))
          ) : FacilityData.length === 0 && !facilityLoader ? (
            <MenuItem disabled>
              <SecondaryTypographyDefault>
                {LanguageData?.No_facilities_found || "No facilities found"}
              </SecondaryTypographyDefault>
            </MenuItem>
          ) : (
            <MenuItem disabled>
              <CircularProgress size={24} />
            </MenuItem>
          )}
          {/* {FacilityData.map((value) => (
            <MenuItem
              onClick={() =>
                handleSelectedFacility(value?.id, value?.title, "Facility")
              }
              key={value?.id}
            >
              {value?.title}
            </MenuItem>
          ))} */}
        </Menu>
        <Menu
          sx={{ borderRadius: "8px", border: "1px solid #EAECF0" }}
          open={commonOpen}
          anchorEl={CommonArea}
          onClose={() => setCommonArea(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          {CommonAreaData.length > 0 ? (
            CommonAreaData.map((value) => (
              <MenuItem
                onClick={() =>
                  handleSelectedCommonArea(
                    value?.id,
                    value?.common_area_name,
                    "Common Area"
                  )
                }
                key={value?.id}
              >
                {value?.common_area_name}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>
              <CircularProgress size={24} />
            </MenuItem>
          )}
          {/* {CommonAreaData.map((value) => (
            <MenuItem
              onClick={() =>
                handleSelectedCommonArea(
                  value?.id,
                  value?.common_area_name,
                  "Common Area"
                )
              }
              key={value?.id}
            >
              {value?.common_area_name}
            </MenuItem>
          ))} */}
        </Menu>

        <Menu
          sx={{ borderRadius: "8px", border: "1px solid #EAECF0" }}
          open={buildingOpen}
          anchorEl={Building}
          onClose={() => setBuilding(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          {BuildingData.length > 0 ? (
            BuildingData.map((value) => (
              <MenuItem
                key={value?.id}
                onClick={() =>
                  handleSelectedBuilding(
                    value?.id,
                    value?.building_name,
                    "Building"
                  )
                }
              >
                {value?.building_name}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>
              <CircularProgress size={24} />
            </MenuItem>
          )}
        </Menu>
        <Menu
          sx={{ borderRadius: "8px", border: "1px solid #EAECF0" }}
          open={accessOpen}
          anchorEl={access}
          onClose={() => SetAccess(null)}
        >
          <MenuItem sx={{ width: "600px" }} onClick={() => handleAccess("In")}>
            <StyledTypography style={{ color: "#344054" }}>
              {" "}
              {LanguageData?.In || "In"}
            </StyledTypography>
          </MenuItem>
          <MenuItem sx={{ width: "600px" }} onClick={() => handleAccess("Out")}>
            <StyledTypography style={{ color: "#344054" }}>
              {LanguageData?.Out || "Out"}
            </StyledTypography>
          </MenuItem>
          <MenuItem
            sx={{ width: "600px" }}
            onClick={() => handleAccess("In & Out")}
          >
            <StyledTypography style={{ color: "#344054" }}>
              {LanguageData?.In_Out || "In & Out"}
            </StyledTypography>
          </MenuItem>
        </Menu>
      </Box>
    </>
  );
};

export default AddDevice;
