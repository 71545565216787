import {
  Box,
  Button,
  Checkbox,
  Menu,
  MenuItem,
  Modal,
  OutlinedInput,
  Pagination,
  CircularProgress,
  PaginationItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import {
  DatePickerStyle,
  ErrorTypography,
  PrimaryTypography,
  SecondaryTypography,
  StyledOutlinedInput,
  StyledSecondaryTypography,
  StyledTableCell,
  StyledTableRow,
  StyledTypography,
  TypographyDefault,
} from "../../../Theme";
import { ReactComponent as SearchIcon } from "../../../Icons/Search.svg";
import { ReactComponent as FilterIcon } from "../../../Icons/Filter.svg";
import { ReactComponent as SortIcon } from "../../../Icons/Sort.svg";
import { ReactComponent as CheckboxIcon } from "../../../Icons/Checkbox.svg";
import { ReactComponent as DeleteIcon } from "../../../Icons/DeleteTable.svg";
import { ReactComponent as PreviousIcon } from "../../../Icons/Previous.svg";
import { ReactComponent as NextIcon } from "../../../Icons/Next.svg";
import { ReactComponent as ExclamatoryIcon } from "../../../Icons/Exclamatory.svg";
import { ReactComponent as MoreIcon } from "../../../Icons/ThreeDot.svg";
import { ReactComponent as NumberInputIcon } from "../../../Icons/NumberInputIcon.svg";
import { ReactComponent as CheckBoxMinusIcon } from "../../../Icons/CheckBoxMinus.svg";
import { ReactComponent as Close } from "../../../Icons/BorderAlertClose.svg";
import { ReactComponent as DatePickerIcon } from "../../../Icons/Datepicker.svg";
import { ReactComponent as DownloadIcon } from "../../../Icons/DownloadIcon.svg";
import { ReactComponent as CsvIcon } from "../../../Icons/CsvIcon.svg";
import { ReactComponent as PdfIcon } from "../../../Icons/PdfIcon.svg";
import { ResidentColumn, UserResidentColumn } from "../../../Data";
import { useNavigate } from "react-router-dom";
import Dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../Redux/AxiosInstance";
import moment, { now } from "moment";
import TypesInformationModal from "../../../CommonComponents/TypeInformationModal";
import { DatePicker } from "@mui/x-date-pickers";
import AlertModal, { PostLoaderModal } from "../../../CommonComponents/modal";
import { ReactComponent as DisabledDownloadIcon } from "../../../Icons/DisableDownload.svg";

const ModalData = [
  {
    title: "Owner",
    description:
      "In a condo unit, there will be users who are tagged as owners. Those who are tagged as owners will be able to edit, remove and change access details for their sub-owners and tenants. For all important questions or clarification towards the resident unit, administrators should approach the owners/ sub-owners first.",
  },
  {
    title: "Sub-Owner",
    description:
      "In a condo unit, there will be users who are tagged as sub-owner, these could be family members and permanent occupants of the flat along with the owner. At any moment, there can only be one owner, but multiple sub-owners. Those who are tagged as sub-owner will be able to edit, remove and change access details for their tenants. For all important questions or clarification towards the resident unit, administrators should approach the owners/ sub-owners first.",
  },
  {
    title: "Tenants",
    description:
      "In a condo unit, there will be users who are tagged as tenants. Those who are tagged as tenants will be able to edit their own details but not of other tenants or owners within the unit. For all important questions or clarification towards the resident unit, administrators should approach the owners first.",
  },
];

let offboardModalData = {
  label: "Offboard User Now",
  descriptionOne:
    "Please confirm that you wish to offboard user now. After offboarded, the information cannot be recovered.",
  buttonOne: {
    variant: "outlined",
    color: "#0E5E84",
    backgroundColor: "#FFFFFF",
    text: "Cancel",
  },
  buttonTwo: {
    variant: "contained",
    color: "#FFFFFF",
    backgroundColor: "#B42318",
    text: "Confirm Offboard",
  },
};

let cancelOffboardData = {
  label: "Cancel Offboard Request",
  descriptionOne:
    "Please confirm that you wish to cancel the offboard request. The resident will be tagged as a permanent user.",
  buttonOne: {
    variant: "outlined",
    color: "#0E5E84",
    backgroundColor: "#FFFFFF",
    text: "Cancel",
  },
  buttonTwo: {
    variant: "contained",
    color: "#FFFFFF",
    backgroundColor: "#0E5E84",
    text: "Confirm",
  },
};

export default function Resident() {
  const [initialRender, setInitialRender] = useState(true);
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { UserManage } = useSelector((state) => state.Login);

  const initialState = { schedule: false, now: false };
  const navigate = useNavigate();
  const [residentList, setResidentList] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [loading, setLoading] = useState(false);
  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [modalCheckboxStatus, setModalCheckboxStatus] = useState({
    schedule: false,
    now: false,
  });
  const [offboardDate, setOffboardDate] = useState(null);
  const [modalError, setModalError] = useState({ date: "", selected: "" });
  const [showAlert, setShowAlert] = useState({
    now: false,
    schedule: false,
    cancelOffboard: false,
  });
  const [isStatus, setIsStatus] = useState(true);
  //change status - multiple
  const [selectedMultipleData, setselectedMultipleData] = useState([]);
  const [changeStatusMultiple, setChangeStatusMultiple] = useState(false);
  const [mainCheck, setMainCheck] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const sortOpen = Boolean(sortAnchorEl);
  const [sortData, setSortData] = useState(null);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const filterOpen = Boolean(filterAnchorEl);
  const [filterData, setFilterData] = useState([]);
  const [moreAnchorEl, setMoreAnchorEl] = useState(null);
  const MoreOpen = Boolean(moreAnchorEl);
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const downloadOpen = Boolean(downloadAnchorEl);
  const [showAnchorEl, setshowAnchorEl] = useState(null);
  const showOpen = Boolean(showAnchorEl);
  const [ResidencyData, setResidencyData] = useState([]);
  const [SearchData, setSearchData] = useState("");
  const [Tableloading, setTableloading] = useState(true);
  const [postLoader, SetPostLoader] = useState(false);

  useEffect(() => {
    getDepartmentData();
  }, []);

  const getAllResidentData = async () => {
    const filterId = filterData.join(",");
    setResidentList([]);
    setTableloading(true);
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}onboard/property/${propertyId}/resident_onboard?page=${currentPage}&record_count=${rowsPerPage}&sort=${sortData}&residency_type_id=${filterId}&search=${SearchData}`
      );
      setResidentList(response.data.data.resident_onboard);
      setPaginationData(response.data.data.pagination_details);
      setTableloading(false);
      // setRowsPerPage(response?.data?.data?.pagination_details?.per_page)
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.error == "No data found") {
        setResidentList([]);
      }
      setTableloading(false);
    }
  };

  // const getAllResidentData = () => axiosInstance.get(`${process.env.REACT_APP_API_URL}onboard/property/${propertyId}/resident_onboard?page=${currentPage}&sort=${sortData}&status_id`)
  //     .then((data) => {
  //         setResidentList(data.data.data.resident_onboard)
  //         setPaginationData(data.data.data.pagination_details)
  //     })
  //     .catch((err) => {
  //         console.log(err)
  //     })

  useEffect(() => {
    getAllResidentData();
  }, [currentPage]);

  useEffect(() => {
    if (!initialRender) {
      if (currentPage != 1) {
        setCurrentPage(1);
      } else {
        getAllResidentData();
      }
    } else {
      setInitialRender(false);
    }
  }, [sortData, filterData, rowsPerPage, SearchData]);

  const handleSelectedFilter = (e, id) => {
    // setCurrentPage('1')
    const Data = filterData?.find((itm) => itm == id);
    if (Data) {
      const Data = filterData?.filter((itm) => itm !== id);
      setFilterData(Data);
    } else {
      setFilterData((itm) => [...itm, id]);
    }
  };

  const handleSearch = async (data) => {
    setSearchData(data);

    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}onboard/property/${propertyId}/resident_onboard?search=${data}`
      );
      setResidentList(response.data.data.resident_onboard);
      setPaginationData(response.data.data.pagination_details);
      setRowsPerPage(response?.data?.data?.pagination_details?.per_page);
    } catch (error) {
      console.log(error);
    }
  };

  const DownloadReport = async (type) => {
    const filterId = filterData.join(",");
    try {
      setLoading(true);
      setDownloadAnchorEl(null);
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}onboard/property/${propertyId}/resident_onboard/download?download_type=${type}&page=${currentPage}&record_count=${rowsPerPage}&sort=${sortData}&residency_type_id=${filterId}&search=${SearchData}`,
        { responseType: "blob" }
      );
      saveAs(response.data, `Residents_report.${type}`);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getDepartmentData = async () => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}getDropDownDatas?type=residency_type_tenant`
      );
      setResidencyData(response?.data?.data?.drop_down);
    } catch (error) {
      console.log(error);
    }
  };

  const handleMoreOptions = (e, value) => {
    setMoreAnchorEl(e.currentTarget);
    setSelectedData(value);
  };

  const handleModalCheckboxStatus = (key) => {
    if (key == "now") {
      setOffboardDate(null);
    }
    setModalCheckboxStatus({
      ...initialState,
      [key]: !modalCheckboxStatus[key],
    });
  };

  const closeModal = () => {
    setChangeStatusModal(false);
    setSelectedData({});
    setOffboardDate(null);
    setModalError({ date: "", selected: "" });
    setModalCheckboxStatus({ schedule: false, now: false });
    SetPostLoader(false);
  };

  const closeAlert = () => {
    setShowAlert({ now: false, schedule: false, cancelOffboard: false });
  };

  const offboardResident = () => {
    const checkScheduleoffboardDateToday =
      moment(offboardDate).format("YYYY-MM-DD") ==
      moment(new Date()).format("YYYY-MM-DD");
    const offboardDateToday = moment(new Date(), "YYYY-MM-DD HH:mm:ss")
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");

    const AccessEnd = offboardDate;
    const finalEnd = moment(AccessEnd).hour(0).minute(0).second(0);
    const final1End = moment.utc(offboardDate).format("YYYY-MM-DD HH:mm:ss");
    SetPostLoader(true);
    const data = {
      user_ids: changeStatusMultiple
        ? selectedMultipleData
        : [selectedData.user_id],
      status: "offboard",
      access_end: modalCheckboxStatus.now
        ? offboardDateToday
        : checkScheduleoffboardDateToday
        ? offboardDateToday
        : final1End,
    };
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}onboard/property/${propertyId}/property_user_status_change`,
        data
      )
      .then((data) => {
        closeAlert();
        closeModal();
        getAllResidentData();
        undo();
        setCurrentPage(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cancelOffboardResident = () => {
    SetPostLoader(true);
    const data = {
      user_ids: changeStatusMultiple
        ? selectedMultipleData
        : [selectedData.user_id],
      status: "active",
    };
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}onboard/property/${propertyId}/property_user_status_change`,
        data
      )
      .then((data) => {
        closeAlert();
        closeModal();
        getAllResidentData();
        undo();
        setCurrentPage(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const validate = () => {
    let hasError = [];

    if (
      modalCheckboxStatus.now == false &&
      modalCheckboxStatus.schedule == false
    ) {
      setModalError({
        ...modalError,
        selected: "Please select one option",
      });
      hasError.push(true);
      return hasError.includes(true);
    } else {
      setModalError({
        ...modalError,
        selected: "",
      });
    }

    if (modalCheckboxStatus.schedule == true && offboardDate == null) {
      setModalError({
        ...modalError,
        date: "Please select offboard date",
      });
      hasError.push(true);
      return hasError.includes(true);
    } else {
      setModalError({
        ...modalError,
        date: "",
      });
    }
  };

  useEffect(() => {
    if (!validate()) {
      setIsStatus(false);
    } else {
      setIsStatus(true);
    }
  }, [modalCheckboxStatus, offboardDate]);

  const handleConfirm = () => {
    let isError = validate();
    if (!isError) {
      if (modalCheckboxStatus.now) {
        setShowAlert({
          ...showAlert,
          now: true,
        });
      } else {
        setShowAlert({
          ...showAlert,
          schedule: true,
        });
      }
    }
  };

  // change status - multiple

  useEffect(() => {
    if (valid()) {
      if (selectedMultipleData?.length > 1) {
        setChangeStatusMultiple(true);
        const data = residentList.find(
          (itm) => itm?.user_id == selectedMultipleData[0]
        );
        setSelectedData(data);
      } else {
        setChangeStatusMultiple(false);
      }
    } else {
      setChangeStatusMultiple(false);
    }
  }, [selectedMultipleData]);

  const handleMultipleCheck = (id) => {
    const find = selectedMultipleData.find((itm) => itm === id);

    if (Boolean(find)) {
      const data = selectedMultipleData.filter((itm) => itm != id);
      setselectedMultipleData(data);
    } else if (!Boolean(find)) {
      setselectedMultipleData((prev) => {
        return [...prev, id];
      });
    }
  };

  const valid = () => {
    let isValid = true;
    const data = residentList.find(
      (itm) => itm.user_id == selectedMultipleData[0]
    );
    const checkedValue = data?.access_end;
    for (let i = 1; i < selectedMultipleData.length; i++) {
      residentList.forEach((itm) => {
        if (selectedMultipleData[i] == itm?.user_id) {
          if (checkedValue != itm?.access_end) {
            isValid = false;
          }
        }
      });
    }
    return isValid;
  };

  useEffect(() => {
    if (mainCheck) {
      setselectedMultipleData([]);
      residentList.map((itm) =>
        setselectedMultipleData((prev) => [...prev, itm?.user_id])
      );
    }
  }, [mainCheck]);

  useEffect(() => {
    if (selectedMultipleData.length <= 1) {
      setMainCheck(false);
    }
  }, [selectedMultipleData]);

  const handleMainCheck = (e) => {
    const { checked } = e.target;
    if (residentList?.length > 1) {
      setMainCheck(checked);
    }
  };

  const undo = () => {
    setMainCheck(false);
    setselectedMultipleData([]);
    setChangeStatusMultiple(false);
  };

  return (
    <>
      {postLoader && <PostLoaderModal />}
      {showModal && (
        <TypesInformationModal
          data={ModalData}
          label={"Explanation of Resident Types"}
          setFunction={setShowModal}
        />
      )}
      {(showAlert.now || showAlert.schedule) && (
        <AlertModal
          modalDatas={offboardModalData}
          confirmFunction={offboardResident}
          closeFunction={closeAlert}
        />
      )}
      {showAlert.cancelOffboard && (
        <AlertModal
          modalDatas={cancelOffboardData}
          confirmFunction={cancelOffboardResident}
          closeFunction={closeAlert}
        />
      )}

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <PrimaryTypography>Resident Management</PrimaryTypography>
          <StyledSecondaryTypography>
            Manage residents and their account permissions here.{" "}
            <span
              onClick={() => setShowModal(true)}
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              Resident Details
            </span>
          </StyledSecondaryTypography>
        </Box>
        {UserManage?.residents && (
          <Box>
            <Button
              onClick={(e) => setDownloadAnchorEl(e.currentTarget)}
              sx={{
                fontWeight: "600",
                height: "40px",
                width: "fit-content",
                color: "#0E5E84",
                backgroundColor: "#E7EFF3",
                padding: "10px 16px 10px 16px",
                mr: 3,
                "&:hover": {
                  backgroundColor: "#CFDFE6",
                },
                "&.Mui-disabled": {
                  backgroundColor: "#F9FAFB",
                  color: "#D2D6DB",
                },
              }}
              disabled={residentList.length === 0 || loading}
            >
              {loading ? (
                <Box sx={{ width: "40px", marginTop: "5px" }}>
                  <CircularProgress size={15} />
                </Box>
              ) : (
                <>
                  {residentList.length > 0 ? (
                    <DownloadIcon
                      style={{ marginRight: "8px", padding: "3px" }}
                    />
                  ) : (
                    <DisabledDownloadIcon
                      style={{ marginRight: "8px", padding: "3px" }}
                    />
                  )}
                </>
              )}
              Download Report
            </Button>
            <Button
              onClick={(e) => setAnchorEl(e.currentTarget)}
              sx={{
                padding: "10px 18px",
                borderRadius: "8px",
                backgroundColor: "#0E5E84",
                color: "#FFF",
                fontSize: "14px",
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: "#022B3E",
                },
              }}
            >
              Add
            </Button>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          border: "1px solid #E5E7EB",
          p: 3,
          mt: 4,
          borderRadius: " 8px 8px 0px 0px",
          display: "flex",
          justifyContent: "space-between",
          overflowX: "scroll",
          "::-webkit-scrollbar": { display: "none" },
        }}
      >
        {changeStatusMultiple ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            height="40px"
          >
            <Box display="flex" alignItems="center">
              <SecondaryTypography mr={2}>
                {selectedMultipleData.length} Selected
              </SecondaryTypography>
              <StyledSecondaryTypography
                sx={{ borderBottomWidth: "2px", borderBottomStyle: "solid" }}
                onClick={() => {
                  setselectedMultipleData([]);
                }}
              >
                Unselect All
              </StyledSecondaryTypography>
            </Box>
            <Box
              sx={{
                display: "flex",
                height: "40px",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #E5E7EB",
                borderRadius: "6px",
                px: 2,
                ml: 2,
              }}
            >
              <SecondaryTypography onClick={() => setChangeStatusModal(true)}>
                Change Status
              </SecondaryTypography>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              width: "100%",
              height: "40px",
            }}
          >
            <StyledOutlinedInput
              fullWidth
              placeholder="Search"
              onChange={(e) => {
                setSearchData(e.target.value);
              }}
              startAdornment={<SearchIcon style={{ paddingRight: "5px" }} />}
            />
            <Box
              onClick={(e) => setSortAnchorEl(e.currentTarget)}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #D2D6DB",
                borderRadius: "6px",
                px: 2,
                ml: 2,
                "&:hover": { cursor: "pointer", backgroundColor: "#F9FAFB" },
              }}
            >
              <SortIcon style={{ marginRight: "10px" }} />
              <SecondaryTypography>Sort</SecondaryTypography>
            </Box>
            <Box
              onClick={(e) => setFilterAnchorEl(e.currentTarget)}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #D2D6DB",
                borderRadius: "6px",
                px: 2,
                ml: 2,
                "&:hover": { cursor: "pointer", backgroundColor: "#F9FAFB" },
              }}
            >
              <FilterIcon style={{ marginRight: "10px" }} />
              <SecondaryTypography>Filters</SecondaryTypography>
            </Box>
          </Box>
        )}
      </Box>

      <Box
        sx={{ border: "1px solid #E5E7EB", borderRadius: "0px 0px 8px 8px" }}
      >
        {residentList?.length > 0 ? (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <StyledTableRow>
                    {(UserManage?.residents
                      ? ResidentColumn
                      : UserResidentColumn
                    ).map((value) => {
                      return (
                        <StyledTableCell>
                          {value === "Checkbox" ? (
                            selectedMultipleData?.length > 1 ? (
                              <Checkbox
                                sx={{ height: "40px", minWidth: "43px" }}
                                onClick={() => setselectedMultipleData([])}
                                size="small"
                                icon={<CheckBoxMinusIcon />}
                              />
                            ) : (
                              <Checkbox
                                sx={{ height: "40px", minWidth: "43px" }}
                                onChange={handleMainCheck}
                                checked={mainCheck}
                                size="small"
                                icon={<CheckboxIcon />}
                              />
                            )
                          ) : value === "More" ? (
                            ""
                          ) : (
                            value
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {residentList?.map((value) => {
                    return (
                      <StyledTableRow>
                        {UserManage?.residents && (
                          <StyledTableCell>
                            {" "}
                            <Checkbox
                              sx={{ height: "40px", minWidth: "43px" }}
                              checked={Boolean(
                                selectedMultipleData.find(
                                  (itm) => itm == value?.user_id
                                )
                              )}
                              onChange={() => {
                                handleMultipleCheck(value.user_id);
                              }}
                              size="small"
                              icon={<CheckboxIcon />}
                            />
                          </StyledTableCell>
                        )}
                        <StyledTableCell
                          onClick={() =>
                            navigate(`reviewResident/${value?.user_id}`)
                          }
                        >
                          {value?.user_details?.personal_details?.first_name}
                        </StyledTableCell>
                        <StyledTableCell
                          onClick={() =>
                            navigate(`reviewResident/${value?.user_id}`)
                          }
                        >
                          {value?.unit_details?.unit_no}
                        </StyledTableCell>
                        <StyledTableCell
                          onClick={() =>
                            navigate(`reviewResident/${value?.user_id}`)
                          }
                        >
                          {moment
                            .utc(value?.access_start)
                            .local()
                            .format("DD MMM YYYY")}
                        </StyledTableCell>
                        <StyledTableCell
                          onClick={() =>
                            navigate(`reviewResident/${value?.user_id}`)
                          }
                        >
                          {value?.residency_type?.name}
                        </StyledTableCell>
                        <StyledTableCell
                          onClick={() =>
                            navigate(`reviewResident/${value?.user_id}`)
                          }
                        >
                          {value?.access_end != null
                            ? moment
                                .utc(value?.access_end)
                                .local()
                                .format("DD MMM YYYY")
                            : "-"}
                        </StyledTableCell>
                        <StyledTableCell
                          onClick={() =>
                            navigate(`reviewResident/${value?.user_id}`)
                          }
                        >
                          {value?.user_details?.email}
                          <StyledSecondaryTypography>
                            {
                              value?.user_details?.personal_details
                                ?.country_code
                            }{" "}
                            {value?.user_details?.personal_details?.phone}
                          </StyledSecondaryTypography>
                        </StyledTableCell>
                        {UserManage?.residents && (
                          <StyledTableCell>
                            <MoreIcon
                              onClick={(e) => {
                                handleMoreOptions(e, value);
                                undo();
                              }}
                            />
                          </StyledTableCell>
                        )}
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderTopStyle: "none !important",
                borderRadius: "0px 0px 8px 8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 2.5,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{ color: "#4D5761", fontSize: "14px", fontWeight: 500 }}
                >
                  Show :
                </Typography>
                {/* <OutlinedInput inputProps={{ min: 5, max: 30, step: 5 }} value={rowsPerPage} onChange={(e) => setRowsPerPage(e.target.value)} type='number' sx={{ height: '40px', width: '100px', ml: 2, borderRadius: '8px' }} /> */}
                <Box
                  onClick={(e) => setshowAnchorEl(e.currentTarget)}
                  sx={{
                    width: "95px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #E5E7EB",
                    borderRadius: "6px",
                    ml: 2,
                    "&:hover": { cursor: "pointer" },
                  }}
                >
                  <Typography
                    sx={{ fontSize: "15px" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {rowsPerPage} rows{" "}
                    <NumberInputIcon style={{ paddingLeft: "5px" }} />
                  </Typography>
                </Box>
              </Box>
              <Pagination
                size="medium"
                page={currentPage}
                count={Math.ceil(paginationData?.total / 20)}
                onChange={(e, value) => setCurrentPage(value)}
                shape="rounded"
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: PreviousIcon, next: NextIcon }}
                    {...item}
                  />
                )}
              />
            </Box>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "50vh",
              justifyContent: "center",
            }}
          >
            {Tableloading ? (
              <CircularProgress />
            ) : (
              <>
                <ExclamatoryIcon />
                <Typography
                  sx={{
                    color: "#9DA4AE",
                    fontSize: "16px",
                    fontWeight: 600,
                    my: 2,
                  }}
                >
                  No Resident Here
                </Typography>
                <StyledSecondaryTypography>
                  There are no residents in the system yet.{" "}
                </StyledSecondaryTypography>
                <StyledSecondaryTypography>
                  If you are authorised, you will see an add button and you can
                  add new residents by clicking the top right add button.
                </StyledSecondaryTypography>
              </>
            )}
          </Box>
        )}
      </Box>
      <Menu
        sx={{ m: 0.5 }}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => navigate("createSingleResident")}>
          <TypographyDefault>Single Onboarding</TypographyDefault>
        </MenuItem>
        <MenuItem onClick={() => navigate("createBatchResident")}>
          <TypographyDefault>Batch Onboarding</TypographyDefault>
        </MenuItem>
      </Menu>

      <Menu
        sx={{ m: 0.5 }}
        open={sortOpen}
        anchorEl={sortAnchorEl}
        onClose={() => setSortAnchorEl(null)}
      >
        <MenuItem
          sx={{ backgroundColor: sortData == "recent" ? "#eff3f5" : "" }}
          onClick={() => {
            setSortData("recent");
            setSortAnchorEl(null);
          }}
        >
          <TypographyDefault>Newest to Oldest</TypographyDefault>
        </MenuItem>
        <MenuItem
          sx={{ backgroundColor: sortData == "old" ? "#eff3f5" : "" }}
          onClick={() => {
            setSortData("old");
            setSortAnchorEl(null);
          }}
        >
          <TypographyDefault>Oldest to Newest</TypographyDefault>
        </MenuItem>
      </Menu>

      <Menu
        sx={{ m: 0.5 }}
        open={filterOpen}
        anchorEl={filterAnchorEl}
        onClose={() => setFilterAnchorEl(null)}
      >
        {ResidencyData?.map((itm) => {
          return (
            <MenuItem onClick={(e) => handleSelectedFilter(e, itm?.id)}>
              <Checkbox
                sx={{ width: "42px", height: "42px" }}
                checked={Boolean(filterData.find((item) => itm?.id == item))}
                icon={<CheckboxIcon />}
              />
              <Typography sx={{ mr: 3 }}>{itm?.name}</Typography>
            </MenuItem>
          );
        })}

        {/* <MenuItem>
                    <Checkbox checked={filterData.find((item) => (3 == item))} onClick={(e) => handleSelectedFilter(e, 3)} icon={<CheckboxIcon />} size='small' />
                    <TypographyDefault >Owner / Sub-Owner</TypographyDefault>
                </MenuItem>
                <MenuItem>
                    <Checkbox checked={filterData.find((item) => (5 == item))} onClick={(e) => handleSelectedFilter(e, 5)} icon={<CheckboxIcon />} size='small' />
                    <TypographyDefault >Tenant</TypographyDefault>
                </MenuItem> */}
        {/* <MenuItem>
                    <Checkbox checked={filterData.find((item) => (itm?.id == item))} onClick={(e) => handleSelectedFilter(e, itm?.id)} icon={<CheckboxIcon />} />
                    <Typography sx={{ mr: 3 }}>{itm?.department_name}</Typography>
                </MenuItem> */}
      </Menu>

      <Menu
        sx={{ m: 0.5 }}
        open={showOpen}
        anchorEl={showAnchorEl}
        onClose={() => setshowAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem
          onClick={() => {
            setRowsPerPage(10);
            setshowAnchorEl(null);
          }}
        >
          <Typography>10 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(20);
            setshowAnchorEl(null);
          }}
        >
          <Typography>20 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(30);
            setshowAnchorEl(null);
          }}
        >
          <Typography>30 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(40);
            setshowAnchorEl(null);
          }}
        >
          <Typography>40 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(50);
            setshowAnchorEl(null);
          }}
        >
          <Typography>50 rows</Typography>
        </MenuItem>
      </Menu>

      <Menu
        sx={{
          m: 0.5,
          "& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper": {
            marginTop: "0px !important",
          },
        }}
        open={MoreOpen}
        anchorEl={moreAnchorEl}
        onClose={() => setMoreAnchorEl(null)}
      >
        <MenuItem
          onClick={() => navigate(`reviewResident/${selectedData.user_id}`)}
        >
          <Typography>View Profile</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setChangeStatusModal(true);
            setMoreAnchorEl(null);
          }}
        >
          <Typography>Change Status</Typography>
        </MenuItem>
      </Menu>

      <Menu
        sx={{ m: 0.5 }}
        open={downloadOpen}
        anchorEl={downloadAnchorEl}
        onClose={() => setDownloadAnchorEl(null)}
      >
        <MenuItem>
          <TypographyDefault
            onClick={() => DownloadReport("csv")}
            py={0.5}
            sx={{
              fontWeight: "500 !important",
              color: "#1F2A37 !important",
              display: "flex",
            }}
          >
            <CsvIcon width={40} /> Download as CSV file
          </TypographyDefault>
        </MenuItem>
        <MenuItem>
          <TypographyDefault
            onClick={() => DownloadReport("pdf")}
            py={0.5}
            sx={{
              fontWeight: "500 !important",
              color: "#1F2A37 !important",
              display: "flex",
            }}
          >
            <PdfIcon width={40} />
            Download as PDF file
          </TypographyDefault>
        </MenuItem>
      </Menu>

      <Modal open={changeStatusModal} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "40%",
              backgroundColor: "#FFF",
              boxShadow:
                "0px 4px 20px 0px rgba(0, 0, 0, 0.15), 0px 0px 3px 0px rgba(0, 0, 0, 0.10)",
              border: "1px solid #F3F4F6",
              borderRadius: "6px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #F3F4F6",
                p: 2,
              }}
            >
              <PrimaryTypography>Change Offboard Date</PrimaryTypography>
              <Close onClick={closeModal} />
            </Box>
            <Stack rowGap={2} sx={{ p: 2 }} direction={"column"}>
              <Typography sx={{ fontSize: "15px", fontWeight: 400 }}>
                You may change the offboard date of the resident below.
              </Typography>
              <Stack direction={"row"} justifyContent={"space-between"}>
                {selectedData?.access_end && (
                  <StyledTypography>
                    Current Offboard Date :{" "}
                    {moment
                      .utc(selectedData?.access_end)
                      .local()
                      .format("DD MMM YYYY")}
                  </StyledTypography>
                )}{" "}
                {selectedData?.access_end != null && (
                  <TypographyDefault
                    onClick={() => {
                      // closeModal()
                      setShowAlert({
                        ...showAlert,
                        cancelOffboard: true,
                      });
                    }}
                    sx={{ textDecoration: "underline" }}
                  >
                    Cancel Offboard
                  </TypographyDefault>
                )}
              </Stack>
              <Stack
                className={modalCheckboxStatus.schedule && "selectedStyle"}
                rowGap={1}
                sx={{
                  backgroundColor: "#F9FAFB",
                  p: 1,
                  "&.selectedStyle": {
                    backgroundColor: "#EDF6FA",
                    border: "1px solid #0E4965",
                    borderRadius: "8px",
                  },
                }}
                direction={"column"}
              >
                {selectedData?.access_end === null ? (
                  <StyledTypography sx={{ my: 1 }}>
                    Schedule Offboard Date
                  </StyledTypography>
                ) : (
                  <StyledTypography sx={{ my: 1 }}>
                    Change Offboard Date
                  </StyledTypography>
                )}
                <Stack
                  direction={"row"}
                  width={"100%"}
                  justifyContent={"space-between"}
                >
                  <StyledSecondaryTypography>
                    To extend or reduce offboard date.
                  </StyledSecondaryTypography>
                  <Checkbox
                    onChange={() => handleModalCheckboxStatus("schedule")}
                    checked={modalCheckboxStatus.schedule}
                    size="small"
                    icon={<CheckboxIcon />}
                  />
                </Stack>
              </Stack>
              <Stack
                className={modalCheckboxStatus.now && "selectedStyle"}
                rowGap={1}
                sx={{
                  backgroundColor: "#F9FAFB",
                  p: 1,
                  "&.selectedStyle": {
                    backgroundColor: "#EDF6FA",
                    border: "1px solid #0E4965",
                    borderRadius: "8px",
                  },
                }}
                direction={"column"}
              >
                <StyledTypography>Offboard User Now</StyledTypography>
                <Stack
                  direction={"row"}
                  width={"100%"}
                  justifyContent={"space-between"}
                >
                  <StyledSecondaryTypography>
                    To offboard the resident immediately. After offboard is
                    complete, the action is non-reversible and user will not be
                    able to access their account.
                  </StyledSecondaryTypography>
                  <Checkbox
                    onChange={() => handleModalCheckboxStatus("now")}
                    checked={modalCheckboxStatus.now}
                    size="small"
                    icon={<CheckboxIcon />}
                  />
                </Stack>
              </Stack>
              {modalCheckboxStatus.schedule && (
                <DatePicker
                  disablePast
                  onChange={(e) => setOffboardDate(e.$d)}
                  format="DD/MM/YYYY"
                  slots={{ openPickerIcon: () => <DatePickerIcon /> }}
                  sx={DatePickerStyle}
                />
              )}
              {/* <ErrorTypography>{modalError.date}</ErrorTypography>
                            <ErrorTypography>{modalError.selected}</ErrorTypography> */}
            </Stack>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                borderTop: "1px solid #F3F4F6",
                p: 2,
              }}
            >
              <Button onClick={closeModal} variant="outlined">
                Cancel
              </Button>
              <Button
                disabled={isStatus}
                onClick={handleConfirm}
                style={{
                  padding: "10px 16px",
                  borderRadius: "8px",
                  backgroundColor: "#0E5E84",
                  color: "#FFF",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
