import {
  Box,
  Button,
  Typography,
  Modal,
  Menu,
  MenuItem,
  TextareaAutosize,
  Grid,
  Stack,
  Skeleton,
} from "@mui/material";
import React, { useState, useEffect, lazy } from "react";
import { ReactComponent as BackArrow } from "../../Icons/BorderBackArrow.svg";
import { ReactComponent as DownloadIcon } from "../../Icons/DownloadIcon.svg";
import { ReactComponent as ServiceExclamatoryIcon } from "../../Icons/ServiceExclamatory.svg";
import { useSelector } from "react-redux";
import {
  PrimaryTypography,
  StyledSecondaryTypography,
  TypographyDefault,
  SecondaryTypography,
} from "../../Theme";
import { ReactComponent as Close } from "../../Icons/BorderAlertClose.svg";
import { ReactComponent as JPGIcon } from "../../Icons/Jpg.svg";
import AlertModal from "../../CommonComponents/modal";
import { ServiceDeclineConfirm } from "../../Data";
import { ReactComponent as CsvIcon } from "../../Icons/CsvIcon.svg";
import { ReactComponent as PdfIcon } from "../../Icons/PdfIcon.svg";
import ServiceRequestStepper from "./ServiceStepper";
import { getChip } from "../../Utils";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../Redux/AxiosInstance";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { saveAs } from "file-saver";
import { PostLoaderModal } from "../../CommonComponents/modal";
import { showToast } from "../../CommonComponents/Toaster";

const ReviewService = () => {
  const { id } = useParams();
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { UserManage } = useSelector((state) => state.Login);
  const { LanguageData } = useSelector((state) => state.Language);
  const navigate = useNavigate();
  const [declineModelOpen, setDeclineModelOpen] = useState(false);
  const [declineTextArea, setDeclineTextArea] = useState("");
  const [secondModalOpen, setSecondModalOpen] = useState(false);
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const [requestReviewData, setRequestReviewData] = useState({});
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [confirmDeclineTrigger, setConfirmDeclineTrigger] = useState(false);
  const downloadOpen = Boolean(downloadAnchorEl);
  const [statusDetails, setStatusDetails] = useState({
    submit: {},
    approve: {},
    cancel: {},
  });
  const [loadingMultiple, setLoadingMultiple] = useState(false);
  const [loadingMap, setLoadingMap] = useState({});
  const [loading, setLoading] = useState(false);
  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [duration, setDuration] = useState(false);

  useEffect(() => {
    setSkeletonLoading(true);
    axiosInstance
      .get(
        `${process.env.REACT_APP_API_URL}service_request/property/${propertyId}/request/${id}`
      )
      .then(async (response) => {
        let ReviewData = await response.data.data.service_request;
        setRequestReviewData({
          ...ReviewData,
          reported_on: moment.utc(ReviewData?.created_at).local(),
        });
        if (ReviewData?.start_date && ReviewData?.end_date) {
          const startDate = new Date(ReviewData.start_date);
          const endDate = new Date(ReviewData.end_date);
          const hoursDifference = Math.floor(
            (endDate - startDate) / (1000 * 60 * 60)
          );
          const minutesDifference =
            Math.round((endDate - startDate) / (1000 * 60)) % 60;
          const formattedDifference = `${hoursDifference}${" "}hrs${" "}${minutesDifference}${" "}mins`;
          if (formattedDifference?.includes("-")) {
            setDuration(false);
          } else {
            setDuration(formattedDifference);
          }
        }
        const reportTrack = ReviewData.report_track;
        const submitDetails = reportTrack.find((item) => item.status.id === 1);
        const approveDetails = reportTrack.find((item) => item.status.id === 2);
        const cancelDetails = reportTrack.find((item) => item.status.id === 3);

        setStatusDetails({
          submit: submitDetails,
          approve: approveDetails,
          cancel: cancelDetails,
        });
        setSkeletonLoading(false);
      })
      .catch(() => {
        setSkeletonLoading(true);
      });
  }, [triggerUpdate, confirmDeclineTrigger, id]);

  const statusDuration = () => {
    if (statusDetails?.cancel || statusDetails?.approve) {
      const hrs = Math.floor(
        (moment
          .utc(
            statusDetails?.cancel?.created_at ||
              statusDetails?.approve?.created_at
          )
          .local()._d -
          moment(requestReviewData?.reported_on)._d) /
          3600000
      );
      if (hrs == 0) {
        return `${Math.floor(
          ((moment
            .utc(
              statusDetails?.cancel?.created_at ||
                statusDetails?.approve?.created_at
            )
            .local()._d -
            moment(requestReviewData?.reported_on)._d) %
            3600000) /
            60000
        )} mins`;
      } else {
        console.log("jmbnvgzfdhf");
        return `${Math.floor(
          (moment
            .utc(
              statusDetails?.cancel?.created_at ||
                statusDetails?.approve?.created_at
            )
            .local()._d -
            moment(requestReviewData?.reported_on)._d) /
            3600000
        )} hrs ${Math.floor(
          ((moment
            .utc(
              statusDetails?.cancel?.created_at ||
                statusDetails?.approve?.created_at
            )
            .local()._d -
            moment(requestReviewData?.reported_on)._d) %
            3600000) /
            60000
        )} mins`;
      }
    } else {
      return `${Math.floor(
        (moment(new Date())._d - moment(requestReviewData?.reported_on)._d) /
          3600000
      )} hrs ${Math.floor(
        ((moment(new Date())._d - moment(requestReviewData?.reported_on)._d) %
          3600000) /
          60000
      )} mins`;
    }
  };

  const handleDeclineModalOpen = () => {
    setDeclineModelOpen(true);
  };

  const handleDeclineModalClose = () => {
    setDeclineModelOpen(false);
    setDeclineTextArea("");
  };

  const handleDeclineContinue = (e) => {
    if (declineTextArea && declineTextArea !== "") {
      e.preventDefault();
      setDeclineModelOpen(false);
      setSecondModalOpen(true);
    }
  };

  const handleBackArrowNavigation = () => {
    navigate("/service-request-list");
  };

  const handleApprove = () => {
    setPostLoading(true);
    const requestData = {
      status_id: 2,
    };
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_URL}service_request/property/${propertyId}/request/${id}/change_status`,
        requestData
      )
      .then((res) => {
        setTriggerUpdate((prev) => !prev);
        setPostLoading(false);
        showToast("Request Updated Successfully");
      })
      .catch(() => {
        setPostLoading(false);
        showToast("Something went wrong. Please try again later", "error");
      });
  };

  const DownloadReport = async () => {
    try {
      setLoading(true);
      setDownloadAnchorEl(null);
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}service_request/property/${propertyId}/request/${id}/download`,
        { responseType: "blob" }
      );
      saveAs(response.data, `Serivce_Request_Report.pdf`);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRequestDeclineConfirm = () => {
    if (declineTextArea && declineTextArea !== "") {
      setPostLoading(true);
      const requestData = {
        status_id: 3,
        comment: declineTextArea,
      };
      axiosInstance
        .post(
          `${process.env.REACT_APP_API_URL}service_request/property/${propertyId}/request/${id}/change_status`,
          requestData
        )
        .then(() => {
          setConfirmDeclineTrigger((prev) => !prev);
          setSecondModalOpen(false);
          setDeclineTextArea("");
          setPostLoading(false);
          showToast("Request Updated Successfully");
        })
        .catch(() => {
          setSecondModalOpen(false);
          setDeclineTextArea("");
          setPostLoading(false);
          showToast("Something went wrong. Please try again later", "error");
        });
    }
  };

  const downloadMultipleImage = async () => {
    const arr = [];
    requestReviewData?.images.map((itm) => arr.push(itm.path));
    const data = { file_path_array: arr };
    try {
      setLoadingMultiple(true);
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}download_file`,
        data,
        { responseType: "blob" }
      );
      saveAs(response.data, "RequestImage.zip");
    } catch (error) {
      console.error("Error downloading image:", error);
    } finally {
      setLoadingMultiple(false);
    }
  };

  const downloadImage = async (path) => {
    const filename = path.split("/")[2];
    const data = { file_path: path };

    try {
      setLoadingMap((prevLoadingMap) => ({ ...prevLoadingMap, [path]: true }));

      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}download_file`,
        data,
        { responseType: "blob" }
      );
      saveAs(response.data, filename);
    } catch (error) {
      console.error("Error downloading image:", error);
    } finally {
      setLoadingMap((prevLoadingMap) => ({ ...prevLoadingMap, [path]: false }));
    }
  };

  return (
    <>
      {postLoading && <PostLoaderModal />}

      <Box
        sx={{
          cursor: "pointer",
          marginBottom: "30px",
        }}
      >
        <BackArrow
          // style={{ marginLeft: "10px" }}
          onClick={handleBackArrowNavigation}
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {skeletonLoading ? (
          <Skeleton width={550} height={50} />
        ) : (
          <Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <PrimaryTypography>
                {LanguageData?.Service_Requests || "Request List"} -{" "}
                {requestReviewData?.service_request_types?.name || "--"}{" "}
                {"#" + requestReviewData?.uuid || "--"}
              </PrimaryTypography>

              <Box sx={{ marginLeft: "5px" }}>
                {getChip(requestReviewData?.status?.key)}
              </Box>
            </Box>

            <StyledSecondaryTypography>
              {LanguageData?.Common_Last || "Last Updated:"}{" "}
              {statusDetails.cancel && statusDetails.cancel.updated_at
                ? moment
                    .utc(statusDetails.cancel.updated_at)
                    .local()
                    .format(`DD MMM YYYY, HH:mm`)
                : statusDetails.approve && statusDetails.approve.updated_at
                ? moment
                    .utc(statusDetails.approve.updated_at)
                    .local()
                    .format(`DD MMM YYYY, HH:mm`)
                : statusDetails.submit && statusDetails.submit.updated_at
                ? moment
                    .utc(statusDetails.submit.updated_at)
                    .local()
                    .format(`DD MMM YYYY, HH:mm`)
                : "-"}
            </StyledSecondaryTypography>
          </Box>
        )}

        {UserManage?.["service-request"] &&
          (skeletonLoading ? (
            <Skeleton width={150} height={50} />
          ) : (
            <Button
              onClick={DownloadReport}
              sx={{
                fontWeight: "600",
                width: "fit-content",
                color: "#0E5E84",
                backgroundColor: "#E7EFF3",
                padding: "10px 16px 10px 16px",
                "&:hover": {
                  backgroundColor: "#CFDFE6",
                },
                "&.Mui-disabled": {
                  backgroundColor: "#F9FAFB",
                  color: "#D2D6DB",
                },
              }}
              disabled={loading}
            >
              {loading ? (
                <Box sx={{ width: "40px", marginTop: "5px" }}>
                  <CircularProgress size={15} />
                </Box>
              ) : (
                <DownloadIcon style={{ marginRight: "8px", padding: "3px" }} />
              )}
              {LanguageData?.home_download_report || "Download Report"}
            </Button>
          ))}
      </Box>
      {skeletonLoading ? (
        <Skeleton width="100%" height={150} />
      ) : (
        <Box
          sx={{
            marginTop: "25px",
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "33.33%",
              padding: "20px",
              borderRadius: "8px 0px 0px 8px",
              border: "0.5px solid #E5E7EB",
              gap: "10px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "14px",

                color: "#4D5761",
              }}
            >
              {LanguageData?.Duration || "Duration"}
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "20px",
                color: "#111927",
              }}
            >
              {/* {duration ? duration : "--"} */}
              {statusDuration()}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "33.33%",
              padding: "20px",
              border: "0.5px solid #E5E7EB",
              gap: "10px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                color: "#4D5761",
              }}
            >
              {LanguageData?.Requested_On || "Requested On"}
            </Typography>
            {requestReviewData && (
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "20px",
                  color: "#111927",
                  lineHeight: "26px",
                }}
              >
                {moment(requestReviewData?.created_at).format("DD MMM YYYY")}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              width: "33.33%",
              padding: "20px",
              border: "0.5px solid #E5E7EB",
              backgroundColor: "#FFFFFF",
              borderRadius: "0px 8px 8px 0px",
            }}
          >
            <Typography
              sx={{
                // width: "310px",
                fontWeight: 500,
                fontSize: "14px",
                color: "#4D5761",
              }}
            >
              {LanguageData?.Closed_On || "Closed On"}
            </Typography>
            {statusDetails.cancel && statusDetails.cancel.updated_at ? (
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "20px",
                  color: "#111927",
                }}
              >
                {moment(statusDetails.cancel?.updated_at).format(`DD MMM YYYY`)}
              </Typography>
            ) : statusDetails.approve && statusDetails.approve.updated_at ? (
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "20px",
                  color: "#111927",
                }}
              >
                {moment(statusDetails.approve?.updated_at).format(
                  `DD MMM YYYY`
                )}
              </Typography>
            ) : (
              "--"
            )}
          </Box>
        </Box>
      )}
      {!skeletonLoading && (
        <>
          {" "}
          {UserManage?.["service-request"] &&
            (statusDetails.approve || statusDetails.cancel ? (
              <></>
            ) : (
              <Box
                sx={{
                  borderRadius: "8px",
                  border: "1px solid #E5E7EB",
                  padding: "20px 16px 20px 16px",
                  backgroundColor: "#FFFFFF",
                  marginTop: "25px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <ServiceExclamatoryIcon />
                  <Box flexGrow={1} marginLeft={"7px"}>
                    <Typography
                      sx={{
                        width: "766px",
                        fontWeight: 600,
                        fontSize: "14px",
                        color: "#111927",
                      }}
                    >
                      {LanguageData?.Take_Action || "Take Action"}
                      <Typography>
                        {LanguageData?.Please_select_on ||
                          "Please select an action to"}{" "}
                        <b>{LanguageData?.Approve || "approve"} </b>
                        {LanguageData?.or || "or"}{" "}
                        <b> {LanguageData?.decline || "decline (cancel)"} </b>{" "}
                        {LanguageData?.the_service ||
                          "the service request from the resident."}
                      </Typography>
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Button
                      onClick={handleApprove}
                      sx={{
                        padding: "10px 16px",
                        borderRadius: "8px",
                        border: "none",
                        backgroundColor: "#0E5E84",
                        color: "#FFFFFF",
                        fontSize: "14px",
                        fontWeight: 600,
                        cursor: "pointer",
                        height: "40px",
                        "&:hover": {
                          backgroundColor: "#022B3E",
                        },
                      }}
                    >
                      {LanguageData?.Approve_cap || "Approve"}
                    </Button>
                    <Button
                      onClick={handleDeclineModalOpen}
                      sx={{
                        height: "40px",
                        padding: "10px 16px 10px 16px",
                        borderRadius: "8px",
                        backgroundColor: "#FFFFFF",
                        color: "#0E5E84",
                        fontSize: "14px",
                        fontWeight: 600,
                        border: "1px solid #D2D6DB",
                        marginLeft: "7px",
                      }}
                    >
                      {LanguageData?.Decline || "Decline"}
                    </Button>
                  </Box>
                </Box>
              </Box>
            ))}{" "}
        </>
      )}

      {statusDetails.cancel && statusDetails.cancel && !skeletonLoading && (
        <Box
          sx={{
            borderRadius: "8px",
            border: "1px solid #FEE6A1",
            padding: "20px 16px 20px 16px",
            backgroundColor: "#FFFAEB",
            marginTop: "25px",
            width: "648px",
          }}
        >
          <Typography
            sx={{ fontSize: "14px", fontWeight: 600, color: "#B54708" }}
          >
            {LanguageData?.Cancellation_Reason || "Cancellation Reason"}{" "}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              color: "#B54708",
              width: "600px",
            }}
          >
            {statusDetails.cancel?.comment}
          </Typography>
        </Box>
      )}
      {skeletonLoading ? (
        <>
          <Stack direction="row">
            <Skeleton
              variant="rounded"
              height={400}
              width="62%"
              sx={{ mt: 2, mr: 5 }}
            />
            <Skeleton
              variant="rounded"
              height={400}
              width="35%"
              sx={{ mt: 2 }}
            />
          </Stack>
        </>
      ) : (
        <Box
          sx={{
            marginTop: "25px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Box>
              <Box
                sx={{
                  borderRadius: "8px 8px 0 0",
                  backgroundColor: "#FCFCFD",
                  borderTop: "1px solid #E5E7EB",
                  borderBottom: "1px solid #E5E7EB",
                  px: 2,
                  height: "60px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <SecondaryTypography>
                  {" "}
                  {LanguageData?.Request_Details || "Request Details"}{" "}
                </SecondaryTypography>
              </Box>
              <Box
                sx={{
                  backgroundColor: "#FFFFFF",
                  marginTop: "23.5px",
                }}
              >
                <Grid sx={{ p: 2 }} container rowSpacing={3}>
                  <Grid item xs={6}>
                    <StyledSecondaryTypography>
                      {" "}
                      {LanguageData?.Title || "Title"}{" "}
                    </StyledSecondaryTypography>
                    {requestReviewData && (
                      <TypographyDefault>
                        {requestReviewData?.title || "--"}
                      </TypographyDefault>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <StyledSecondaryTypography>
                      {LanguageData?.Location || "Location"}
                    </StyledSecondaryTypography>
                    {requestReviewData.access_details && (
                      <TypographyDefault>
                        {requestReviewData?.access_details?.building_name ||
                          "--"}
                      </TypographyDefault>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    padding: "12px 16px 20px 16px",
                    borderTop: "1px solid #F3F4F6",
                  }}
                >
                  <StyledSecondaryTypography>
                    {LanguageData?.Description || "Description"}
                  </StyledSecondaryTypography>
                  <TypographyDefault>
                    {requestReviewData?.description || "--"}
                  </TypographyDefault>
                </Grid>
                {requestReviewData?.service_request_types_id === 1 && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      padding: "12px 16px 20px 16px",
                      borderTop: "1px solid #F3F4F6",
                    }}
                  >
                    <StyledSecondaryTypography>
                      {LanguageData?.Date_Of_Usage || "Date Of Usage"}
                    </StyledSecondaryTypography>
                    <TypographyDefault>
                      {moment(requestReviewData?.start_date).format(
                        "DD MMM YYYY"
                      )}
                    </TypographyDefault>
                  </Grid>
                )}
                {requestReviewData?.service_request_types_id === 2 && (
                  <Box sx={{ borderTop: "1px solid #F3F4F6" }}>
                    <Grid
                      sx={{
                        padding: "12px 16px 20px 16px",
                      }}
                      container
                      rowSpacing={3}
                    >
                      <Grid item xs={6}>
                        <StyledSecondaryTypography>
                          {LanguageData?.Date_Range_Of_Clearance ||
                            "Date Range Of Clearance"}
                        </StyledSecondaryTypography>
                        {requestReviewData && (
                          <TypographyDefault>
                            {`${moment(requestReviewData.start_date).format(
                              "DD MMM YYYY"
                            )} \u2010 ${moment(
                              requestReviewData.end_date
                            ).format("DD MMM YYYY")}`}
                          </TypographyDefault>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <StyledSecondaryTypography>
                          {LanguageData?.Require_The_Use_of_Cargo_Lift ||
                            "Require The Use of Cargo Lift"}
                        </StyledSecondaryTypography>
                        {requestReviewData.require_the_use_of_cargo_lift ? (
                          <TypographyDefault>
                            {" "}
                            {LanguageData?.Yes || "Yes"}{" "}
                          </TypographyDefault>
                        ) : (
                          <TypographyDefault>
                            {LanguageData?.No || "No"}
                          </TypographyDefault>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {requestReviewData?.service_request_types_id === 3 && (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      padding: "12px 16px 20px 16px",
                      borderTop: "1px solid #F3F4F6",
                    }}
                  >
                    <StyledSecondaryTypography>
                      {LanguageData?.Date_Range || "Date Range"}
                    </StyledSecondaryTypography>
                    <TypographyDefault>
                      {`${moment(requestReviewData.start_date).format(
                        "DD MMM YYYY"
                      )} \u2010 ${moment(requestReviewData.end_date).format(
                        "DD MMM YYYY"
                      )}`}
                    </TypographyDefault>
                  </Grid>
                )}
              </Box>
              <Box
                sx={{
                  width: "650px",
                  backgroundColor: "#FCFCFD",
                  borderTop: "1px solid #E5E7EB",
                  borderBottom: "1px solid #E5E7EB",
                  px: 2,
                  height: "60px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <SecondaryTypography>
                  {" "}
                  {LanguageData?.Resident_Details || "Resident Details"}{" "}
                </SecondaryTypography>
              </Box>
              <Box
                sx={{
                  borderRadius:
                    requestReviewData?.service_request_types?.key ===
                    "cargo-lift-usage"
                      ? "0px 0px 8px 8px"
                      : "initial",
                  backgroundColor: "#FFFFFF",
                }}
              >
                <Box sx={{ padding: "12px 16px 20px 16px" }}>
                  <Grid container rowSpacing={3}>
                    <Grid item xs={6}>
                      <StyledSecondaryTypography>
                        {LanguageData?.Submitted_By || "Submitted By"}
                      </StyledSecondaryTypography>
                      {statusDetails.submit && (
                        <TypographyDefault>
                          {statusDetails.submit.updated_user?.full_name || "--"}
                        </TypographyDefault>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <StyledSecondaryTypography>
                        {LanguageData?.Email_Address || "Email Address"}
                      </StyledSecondaryTypography>
                      {statusDetails.submit && (
                        <TypographyDefault>
                          {statusDetails.submit.updated_user?.email || "--"}
                        </TypographyDefault>
                      )}
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ borderTop: "1px solid #F3F4F6" }}>
                  <Grid
                    container
                    rowSpacing={3}
                    sx={{ padding: "12px 16px 20px 16px" }}
                  >
                    <Grid item xs={6}>
                      <StyledSecondaryTypography>
                        {LanguageData?.Contact_Number || "Contact Number"}
                      </StyledSecondaryTypography>
                      {statusDetails.submit && (
                        <TypographyDefault>
                          {statusDetails.submit.updated_user?.country_code +
                            statusDetails.submit.updated_user?.phone || "--"}
                        </TypographyDefault>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <StyledSecondaryTypography>
                        {LanguageData?.Unit || "Unit"}
                      </StyledSecondaryTypography>
                      {requestReviewData.access_details && (
                        <TypographyDefault>
                          {requestReviewData.access_details.unit_no || "--"}
                        </TypographyDefault>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              {requestReviewData?.service_request_types_id === 2 && (
                <Box
                  sx={{
                    backgroundColor: "#FCFCFD",
                    borderTop: "1px solid #E5E7EB",
                    borderBottom: "1px solid #E5E7EB",
                    px: 2,
                    height: "60px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <SecondaryTypography>
                    {LanguageData?.Person_In || "Person In Charge Details"}
                  </SecondaryTypography>
                </Box>
              )}
              {requestReviewData?.service_request_types_id === 3 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: "#FCFCFD",
                    borderTop: "1px solid #E5E7EB",
                    borderBottom: "1px solid #E5E7EB",
                    px: 2,
                    height: "60px",
                  }}
                >
                  <SecondaryTypography sx={{ fontSize: "18px !important" }}>
                    {LanguageData?.Supporting_Images || "Supporting Images"}
                  </SecondaryTypography>
                  {requestReviewData?.images?.length > 0 ? (
                    <Button
                      onClick={downloadMultipleImage}
                      variant="outlined"
                      sx={{
                        fontWeight: "600",
                        width: "fit-content",
                        color: "#0E5E84",
                        backgroundColor: "#FFF",
                        paddingRight: "20px !important",
                        mr: 3,
                      }}
                      disabled={loadingMultiple}
                    >
                      {loadingMultiple ? (
                        <Box sx={{ width: "40px", marginTop: "5px" }}>
                          <CircularProgress size={15} />
                        </Box>
                      ) : (
                        <DownloadIcon width={40} />
                      )}
                      {LanguageData?.Download_All || "Download All"}
                    </Button>
                  ) : null}
                </Box>
              )}

              {requestReviewData?.service_request_types_id === 2 && (
                <Box
                  sx={{
                    borderRadius: "0px 0px 8px 8px",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  <Box sx={{ padding: "12px 16px 20px 16px" }}>
                    <Grid container rowSpacing={3}>
                      <Grid item xs={6}>
                        <StyledSecondaryTypography>
                          {LanguageData?.Person_In_Charge || "Person In Charge"}
                        </StyledSecondaryTypography>
                        {requestReviewData?.person_incharge && (
                          <TypographyDefault>
                            {requestReviewData?.person_incharge
                              ?.person_in_charge || "--"}
                          </TypographyDefault>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <StyledSecondaryTypography>
                          {LanguageData?.Contact_Number || "Contact Number"}
                        </StyledSecondaryTypography>
                        {requestReviewData?.person_incharge && (
                          <TypographyDefault>
                            {requestReviewData?.person_incharge?.phone || "--"}
                          </TypographyDefault>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        borderTop: "1px solid #F3F4F6",
                        padding: "12px 16px 20px 16px",
                      }}
                    >
                      <StyledSecondaryTypography>
                        {LanguageData?.Company || "Company"}
                      </StyledSecondaryTypography>
                      <TypographyDefault>
                        {requestReviewData?.person_incharge?.company || "--"}
                      </TypographyDefault>
                    </Grid>
                  </Box>
                </Box>
              )}

              {requestReviewData?.service_request_types_id === 3 && (
                <Box
                  sx={{
                    p: 2,
                    backgroundColor: "#FFFFFF",
                    borderRadius: "0px 0px 8px 8px",
                  }}
                >
                  {requestReviewData?.images?.length > 0 ? (
                    requestReviewData.images.map((itm) => (
                      <Box
                        key={itm.path}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          border: "1px solid #EAECF0",
                          borderRadius: "6px",
                          p: 2,
                          mt: 1.5,
                        }}
                      >
                        <Stack direction="row">
                          <JPGIcon style={{ marginRight: "12px" }} />
                          <Stack direction="column">
                            <StyledSecondaryTypography>
                              {itm.name}
                            </StyledSecondaryTypography>
                            <StyledSecondaryTypography>
                              {(itm.file_size / 1048576).toFixed(2)} MB
                            </StyledSecondaryTypography>
                          </Stack>
                        </Stack>
                        {loadingMap[itm.path] ? (
                          <Box sx={{ width: "40px" }}>
                            <CircularProgress size={20} />
                          </Box>
                        ) : (
                          <DownloadIcon
                            onClick={() => downloadImage(itm.path)}
                            cursor={"pointer"}
                            width={40}
                          />
                        )}
                      </Box>
                    ))
                  ) : (
                    <TypographyDefault>
                      {LanguageData?.Common_Noimage || "No images available"}{" "}
                    </TypographyDefault>
                  )}
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              marginLeft: "51px",
              marginTop: statusDetails.cancel ? "-110px" : "default",
            }}
          >
            <ServiceRequestStepper serviceStatus={statusDetails} />
          </Box>
        </Box>
      )}
      {declineModelOpen && (
        <Modal open={true}>
          <Box
            sx={{
              position: "absolute",
              border: "none",
              width: "100%",
              height: "90%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                width: "40%",
                borderRadius: "8px",
              }}
            >
              <Box
                sx={{
                  padding: "16px 24px 16px 24px",
                  borderBottom: "1px solid #F3F4F6",
                  borderRadius: "8px 8px 0px 0px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <PrimaryTypography>
                  {" "}
                  {LanguageData?.Decline_Request || "Decline Request"}{" "}
                </PrimaryTypography>
                <Close
                  style={{ cursor: "pointer" }}
                  onClick={handleDeclineModalClose}
                />
              </Box>
              <Box sx={{ padding: "30px 30px 30px 30px" }}>
                <Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <TypographyDefault sx={{ fontWeight: "600 !important" }}>
                      {LanguageData?.Cancellation_Reason ||
                        "Cancellation Reason"}
                    </TypographyDefault>
                    <Typography
                      sx={{
                        height: "16px",
                        color: "#9DA4AE",
                        fontWeight: 400,
                        fontSize: "12px",
                      }}
                    >
                      {declineTextArea.length}/300{" "}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      borderRadius: "8px",
                      // padding: "12px 14px 12px 14px",
                      marginTop: "10px",
                      marginRight: "30px",
                    }}
                  >
                    <TextareaAutosize
                      style={{
                        color: "#6C737F",
                        backgroundColor: "#F9FAFB",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "24px",
                        width: "100%",
                        height: "156px",
                        border: "none",
                        resize: "none",
                        cursor: "auto",
                        borderRadius: "8px",
                        padding: "12px 14px",
                        outline: "none",
                      }}
                      placeholder="Please provide a reason for cancellation of case.(This comment is visible to all users)"
                      maxLength={300}
                      onChange={(e) => setDeclineTextArea(e.target.value)}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "0px 0px 8px 8px",
                    borderTop: "1px solid #E5E7EB",
                    backgroundColor: "#FFFFFF",
                    paddingTop: "24px",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    onClick={handleDeclineModalClose}
                    sx={{
                      borderRadius: "8px",
                      border: "1px solid #D2D6DB",
                      padding: "10px 16px 10px 16px",
                      fontWeight: 600,
                      fontSize: "14px",
                      color: "#0E5E84",
                    }}
                  >
                    {LanguageData?.Announcement_Cancel || "Cancel"}
                  </Button>
                  <Button
                    disabled={!(declineTextArea && declineTextArea !== "")}
                    onClick={handleDeclineContinue}
                    style={{
                      borderRadius: "8px",
                      padding: "10px 16px 10px 16px",
                      fontWeight: 600,
                      fontSize: "14px",
                      color: "#FFFFFF",
                      cursor: "pointer",
                      border: "none",
                      backgroundColor: "#0E5E84",
                    }}
                  >
                    {LanguageData?.Common_Continue || "Continue"}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
      {secondModalOpen && (
        <AlertModal
          modalDatas={ServiceDeclineConfirm}
          closeFunction={() => setSecondModalOpen(false)}
          confirmFunction={handleRequestDeclineConfirm}
        />
      )}
      <Menu
        sx={{ m: 0.5 }}
        open={downloadOpen}
        anchorEl={downloadAnchorEl}
        onClose={() => setDownloadAnchorEl(null)}
      >
        <MenuItem>
          <TypographyDefault
            py={0.5}
            sx={{
              fontWeight: "500 !important",
              color: "#1F2A37 !important",
              display: "flex",
            }}
          >
            <CsvIcon width={40} />{" "}
            {LanguageData?.Download_csv || "Download as CSV file"}
          </TypographyDefault>
        </MenuItem>
        <MenuItem>
          <TypographyDefault
            py={0.5}
            sx={{
              fontWeight: "500 !important",
              color: "#1F2A37 !important",
              display: "flex",
            }}
          >
            <PdfIcon width={40} />
            {LanguageData?.Download_pdf || "Download as PDF file"}
          </TypographyDefault>
        </MenuItem>
      </Menu>
    </>
  );
};

export default ReviewService;
