import {
  Box,
  Button,
  Alert,
  Checkbox,
  Select,
  Stack,
  TextField,
  IconButton,
  CircularProgress,
  OutlinedInput,
  Pagination,
  PaginationItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import {
  PrimaryTypography,
  SecondaryTypography,
  StyledOutlinedInput,
  StyledSecondaryDefaultTypography,
  StyledSecondaryBookingTypography,
  StyledSecondaryTypography,
  StyledTableCell,
  StyledTableRow,
  TypographyDefault,
} from "../../../Theme";
import { ReactComponent as SearchIcon } from "../../../Icons/Search.svg";
import { ReactComponent as FilterIcon } from "../../../Icons/Filter.svg";
import { ReactComponent as SortIcon } from "../../../Icons/Sort.svg";
import { ReactComponent as CheckboxIcon } from "../../../Icons/Checkbox.svg";
import { ReactComponent as DeleteIcon } from "../../../Icons/DeleteTable.svg";
import { ReactComponent as PreviousIcon } from "../../../Icons/Previous.svg";
import { ReactComponent as NumberInputIcon } from "../../../Icons/NumberInputIcon.svg";
import { ReactComponent as NextIcon } from "../../../Icons/Next.svg";
import { ReactComponent as ExclamatoryIcon } from "../../../Icons/Exclamatory.svg";
import { ReactComponent as SelectDropDownIcon } from "../../../Icons/SelectDropDown.svg";
import { ReactComponent as ContactDetailsIcon } from "../../../Icons/ContactDetails.svg";
import { ReactComponent as CsvIcon } from "../../../Icons/CsvIcon.svg";
import { ReactComponent as DatePickerIcon } from "../../../Icons/Datepicker.svg";
import { ReactComponent as AlertCloseIcon } from "../../../Icons/AlertClose.svg";
import { ReactComponent as ContactBookToasterIcon } from "../../../Icons/ContactBookToaster.svg";
import { ReactComponent as PdfIcon } from "../../../Icons/PdfIcon.svg";
import { ReactComponent as DownloadIcon } from "../../../Icons/DownloadIcon.svg";
import moment from "moment";
import { FacilityManageColumn } from "../../../Data";
import axiosInstance from "../../../Redux/AxiosInstance";
import { useNavigate } from "react-router-dom";
// import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { makeStyles } from "@mui/styles";
import { getChip } from "../../../Utils";
import { DateRangePicker } from "mui-daterange-picker";
import { Toaster } from "../../../CommonComponents/modal";
import { setfacilitySliceToNull } from "../../../Redux/Feature/Facility/FacilitySlice";
import { ReactComponent as DisabledDownloadIcon } from "../../../Icons/DisableDownload.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const FacilityManage = () => {
  const [initialRender, setInitialRender] = useState(true);
  const { LanguageData } = useSelector((state) => state.Language);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState({});

  const toggle = () => setOpen(!open);

  const navigate = useNavigate();
  const { propertyId } = useSelector((state) => state.PropertyDetails);
  const { UserManage } = useSelector((state) => state.Login);
  const [paginationData, setPaginationData] = useState({});
  const initialState = useSelector((state) => state.Facility);
  const [rowsPerPage, setRowsPerPage] = useState("10");
  const [currentPage, setCurrentPage] = useState(1);
  const [FacilityManageList, setFacilityManageList] = useState([]);
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const downloadOpen = Boolean(downloadAnchorEl);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const filterOpen = Boolean(filterAnchorEl);
  const [filterData, setFilterData] = useState("");
  const [location, setLocation] = useState("");
  const [buildingData, setBuildingData] = useState([]);
  const [toaster, settoaster] = useState({ message: "", delStatus: false });
  const [showAnchorEl, setshowAnchorEl] = useState(null);
  const showOpen = Boolean(showAnchorEl);
  const [Tableloading, setTableloading] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    for (const key in initialState) {
      if (initialState[key] != null) {
        if (initialState[key].success == true) {
          // settoaster({ message: initialState[key].message, delStatus: (key == 'deleteFacilityStatus') ? true : false })
          dispatch(setfacilitySliceToNull());
        }
      }
    }
  }, [initialState]);

  useEffect(() => {
    const getBuildingData = async () => {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}property/${propertyId}/building`
        );
        const BuildingData = response.data.data.property_buildings;
        setBuildingData(BuildingData);
        setPaginationData(response.data.data.pagination_details);
      } catch (error) {
        console.log(error.message);
      }
    };
    getBuildingData();
  }, []);

  const getAllFacility = () => {
    setFacilityManageList([]);
    setTableloading(true);
    axiosInstance
      .get(
        `${
          process.env.REACT_APP_API_URL
        }facility/property/${propertyId}?page=${currentPage}&record_count=${rowsPerPage}&status_id=${filterData}&building_id=${
          location == "all" ? "" : location
        }`
      )
      .then((data) => {
        setFacilityManageList(data?.data?.data?.facility);
        setPaginationData(data.data.data.pagination_details);
        setTableloading(false);
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.error == "No data found.") {
          setFacilityManageList([]);
        }
        setTableloading(false);
      });
  };

  // const getMarketingTabData = async () => {
  //     await axiosInstance.get(`${process.env.REACT_APP_API_URL}property/${propertyId}/marketing?page=${currentPage}&status_id=${TabId}&record_count=${rowsPerPage}&marketing_type_id=${filterData}&sort=${sortData}`)
  //         .then((data) => {
  //             setmaketingDataList(data.data.data.marketing)
  //             setPaginationData(data.data.data.pagination_details)
  //         })
  //         .catch((err) => {
  //             console.log(err)
  //         })
  // }

  useEffect(() => {
    getAllFacility();
  }, [currentPage]);

  useEffect(() => {
    if (!initialRender) {
      if (currentPage != 1) {
        setCurrentPage(1);
      } else {
        getAllFacility();
      }
    } else {
      setInitialRender(false);
    }
  }, [rowsPerPage, filterData, location]);

  const handleSearch = async (data) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}facility/property/${propertyId}?page=${currentPage}&record_count=${rowsPerPage}&status_id=${filterData}&search=${data}`
      );
      setFacilityManageList(response.data.data.facility);
      setPaginationData(response.data.data.pagination_details);
      setRowsPerPage(response?.data?.data?.pagination_details?.per_page);
    } catch (error) {
      console.log(error);
    }
  };

  const handleNavigate = (id) => {
    navigate(`reviewFacilityManage/${id}`);
  };

  const DownloadReport = async (type) => {
    try {
      setLoading(true);
      setDownloadAnchorEl(null);
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}facility/property/${propertyId}/download?download_type=${type}&page=${currentPage}&record_count=${rowsPerPage}&status_id=${filterData}`,
        { responseType: "blob" }
      );
      saveAs(response.data, `Facility_manage_report.${type}`);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {/* <DateRangePicker
                sx={{'& .css-h4y409-MuiList-root':{width:"200px !important"}}}
                    open={true}
                    toggle={toggle}
                    onChange={(range) => setDateRange(range)}
                /> */}
        <Box>
          <PrimaryTypography>
            {LanguageData?.Facilities || "Facilities"}
          </PrimaryTypography>
          <StyledSecondaryTypography>
            {LanguageData?.Facility_Sub ||
              "Manage the facilities within your condo complex on the LOBY system."}
          </StyledSecondaryTypography>
        </Box>
        {UserManage?.manage && (
          <Box display="flex">
            <Button
              onClick={(e) => setDownloadAnchorEl(e.currentTarget)}
              sx={{
                height: "40px !important",
                fontWeight: "600",
                width: "fit-content",
                color: "#0E5E84",
                backgroundColor: "#E7EFF3",
                padding: "10px 16px 10px 16px",
                mr: 3,
                "&:hover": {
                  backgroundColor: "#CFDFE6",
                },
                "&.Mui-disabled": {
                  backgroundColor: "#F9FAFB",
                  color: "#D2D6DB",
                },
              }}
              disabled={FacilityManageList.length === 0 || loading}
            >
              {loading ? (
                <Box sx={{ width: "40px", marginTop: "5px" }}>
                  <CircularProgress size={15} />
                </Box>
              ) : (
                <>
                  {FacilityManageList.length > 0 ? (
                    <DownloadIcon
                      style={{ marginRight: "8px", padding: "3px" }}
                    />
                  ) : (
                    <DisabledDownloadIcon
                      style={{ marginRight: "8px", padding: "3px" }}
                    />
                  )}
                </>
              )}
              {LanguageData?.home_download_report || "Download Report"}
            </Button>
            <Button
              sx={{
                padding: "10px 16px",
                borderRadius: "8px",
                backgroundColor: "#0E5E84",
                color: "#FFF",
                fontSize: "14px",
                fontWeight: 600,
                "&:hover": {
                  backgroundColor: "#022B3E",
                },
              }}
              onClick={() => navigate("createFacility")}
            >
              {LanguageData?.Announcement_create || "Create"}
            </Button>
          </Box>
        )}
        <Menu
          sx={{ m: 0.5 }}
          open={downloadOpen}
          anchorEl={downloadAnchorEl}
          onClose={() => setDownloadAnchorEl(null)}
        >
          <MenuItem>
            <TypographyDefault
              onClick={() => DownloadReport("csv")}
              py={0.5}
              sx={{
                fontWeight: "500 !important",
                color: "#1F2A37 !important",
                display: "flex",
              }}
            >
              <CsvIcon width={40} />{" "}
              {LanguageData?.Download_csv || "Download as CSV file"}
            </TypographyDefault>
          </MenuItem>
          <MenuItem>
            <TypographyDefault
              onClick={() => DownloadReport("pdf")}
              py={0.5}
              sx={{
                fontWeight: "500 !important",
                color: "#1F2A37 !important",
                display: "flex",
              }}
            >
              <PdfIcon width={40} />
              {LanguageData?.Download_pdf || "Download as PDF file"}
            </TypographyDefault>
          </MenuItem>
        </Menu>
      </Box>

      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          border: "1px solid #E5E7EB",
          padding: "20px 16px 20px 16px",
          mt: 4,
          borderRadius: " 8px 8px 0px 0px",
          overflowX: "scroll",
          "::-webkit-scrollbar": { display: "none" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: "44px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              "& >*": { backgroundColor: "white !important" },
            }}
          >
            <Select
              name="level"
              IconComponent={KeyboardArrowDownIcon}
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              sx={{
                border: "1px solid #E5E7EB",
                borderRadius: "6px",
                minWidth: "145px",
              }}
              displayEmpty
              renderValue={
                location !== ""
                  ? undefined
                  : () => (
                      <StyledSecondaryBookingTypography sx={{ ml: 1 }}>
                        {LanguageData?.Selected_Location || "Select location"}
                      </StyledSecondaryBookingTypography>
                    )
              }
            >
              <MenuItem value="all">
                {" "}
                {LanguageData?.All_Locations || "All locations"}
              </MenuItem>
              {buildingData.map((itm, index) => {
                return (
                  <MenuItem key={index} value={itm.id}>
                    {itm.building_name}
                  </MenuItem>
                );
              })}
              {/* <MenuItem >Common Area</MenuItem> */}
            </Select>
          </Box>
          <Box
            onClick={(e) => {
              setFilterAnchorEl(e.currentTarget);
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #D2D6DB",
              borderRadius: "8px",
              px: 2,
              "&:hover": { cursor: "pointer", backgroundColor: "#F9FAFB" },
            }}
          >
            <FilterIcon style={{ marginRight: "10px" }} />
            <StyledSecondaryBookingTypography>
              {LanguageData?.filter_common || "Filters"}
            </StyledSecondaryBookingTypography>
          </Box>
        </Box>
      </Box>
      {FacilityManageList?.length > 0 ? (
        <>
          <Box
            sx={{
              border: "1px solid #E5E7EB",
              borderRadius: "0px 0px 8px 8px",
            }}
          >
            <TableContainer>
              <Table>
                <TableHead>
                  <StyledTableRow>
                    {FacilityManageColumn.map((value) => {
                      return (
                        <StyledTableCell>
                          {value === "Checkbox" ? (
                            <Checkbox size="small" icon={<CheckboxIcon />} />
                          ) : value === "More" ? (
                            ""
                          ) : (
                            value
                          )}
                        </StyledTableCell>
                      );
                    })}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {FacilityManageList?.map((value, index) => {
                    return (
                      <StyledTableRow
                        sx={{ height: "70px" }}
                        key={index}
                        onClick={() => handleNavigate(value.id)}
                      >
                        <StyledTableCell>{value?.title}</StyledTableCell>
                        <StyledTableCell>
                          {getChip(value?.status?.key)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {value?.common_area_id
                            ? value?.location_details?.name
                            : value?.location_details?.building?.building_name}
                        </StyledTableCell>
                        <StyledTableCell>
                          {value?.location_details?.level?.level}
                        </StyledTableCell>
                        <StyledTableCell>
                          {value?.booking_type_details?.name}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                borderTopStyle: "none !important",

                borderRadius: "0px 0px 8px 8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 2.5,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{ color: "#4D5761", fontSize: "14px", fontWeight: 500 }}
                >
                  {LanguageData?.Common_Show || "Show :"}
                </Typography>
                {/* <OutlinedInput inputProps={{ min: 5, max: 30, step: 5 }} value={rowsPerPage} onChange={(e) => setRowsPerPage(e.target.value)} type='number' sx={{ height: '40px', width: '100px', ml: 2, borderRadius: '8px' }} /> */}
                <Box
                  onClick={(e) => setshowAnchorEl(e.currentTarget)}
                  sx={{
                    width: "95px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid #E5E7EB",
                    borderRadius: "6px",
                    ml: 2,
                    "&:hover": { cursor: "pointer" },
                  }}
                >
                  <Typography
                    sx={{ fontSize: "15px" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {rowsPerPage} rows{" "}
                    <NumberInputIcon style={{ paddingLeft: "5px" }} />
                  </Typography>
                </Box>
              </Box>
              <Pagination
                size="medium"
                page={currentPage}
                count={paginationData?.last_page}
                onChange={(e, value) => setCurrentPage(value)}
                shape="rounded"
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: PreviousIcon, next: NextIcon }}
                    {...item}
                  />
                )}
              />
            </Box>
          </Box>
        </>
      ) : (
        <Box
          gap={1}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "50vh",
            justifyContent: "center",
            backgroundColor: "#FFFFFF",
            borderRadius: " 0 0 8px 8px",
          }}
        >
          {Tableloading ? (
            <CircularProgress />
          ) : (
            <>
              <ContactDetailsIcon />
              <Typography
                sx={{
                  color: "#9DA4AE",
                  fontSize: "16px",
                  fontWeight: 600,
                  mt: 2,
                }}
              >
                {LanguageData?.NoExisting_Facility || "No existing facility"}
              </Typography>
              {UserManage?.manage && (
                <>
                  <StyledSecondaryDefaultTypography>
                    {LanguageData?.NoExisting_Facility_sub ||
                      "You may add facilities by clicking on the create button."}
                  </StyledSecondaryDefaultTypography>
                  <Button
                   
                    onClick={() => navigate("createFacility")}
                    sx={{
                      padding: "10px 16px",
                      mt: 2 ,
                      borderRadius: "8px",
                      backgroundColor: "#0E5E84",
                      color: "#FFF",
                      fontSize: "14px",
                      fontWeight: 600,
                      "&:hover": {
                        backgroundColor: "#022B3E",
                      },
                    }}
                  >
                    {LanguageData?.Announcement_create || "Create"}
                  </Button>
                </>
              )}
            </>
          )}
        </Box>
      )}

      <Menu
        open={filterOpen}
        anchorEl={filterAnchorEl}
        onClose={() => setFilterAnchorEl(null)}
      >
        <MenuItem
          sx={{ backgroundColor: filterData == "" ? "#eff3f5" : "" }}
          onClick={() => {
            setFilterData("");
            setFilterAnchorEl(null);
          }}
        >
          <Typography>{LanguageData?.all_filter || "All"} </Typography>
        </MenuItem>
        <MenuItem
          sx={{ backgroundColor: filterData == "1" ? "#eff3f5" : "" }}
          onClick={() => {
            setFilterData("1");
            setFilterAnchorEl(null);
          }}
        >
          <Typography> {LanguageData?.home_active || "Active"} </Typography>
        </MenuItem>
        <MenuItem
          sx={{ backgroundColor: filterData == "2" ? "#eff3f5" : "" }}
          onClick={() => {
            setFilterData("2");
            setFilterAnchorEl(null);
          }}
        >
          <Typography sx={{ mr: 5 }}>
            {" "}
            {LanguageData?.home_inactive || "Inactive"}{" "}
          </Typography>
        </MenuItem>
      </Menu>

      <Menu
        sx={{ m: 0.5 }}
        open={showOpen}
        anchorEl={showAnchorEl}
        onClose={() => setshowAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
      >
        <MenuItem
          onClick={() => {
            setRowsPerPage(10);
            setshowAnchorEl(null);
          }}
        >
          <Typography>10 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(20);
            setshowAnchorEl(null);
          }}
        >
          <Typography>20 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(30);
            setshowAnchorEl(null);
          }}
        >
          <Typography>30 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(40);
            setshowAnchorEl(null);
          }}
        >
          <Typography>40 rows</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setRowsPerPage(50);
            setshowAnchorEl(null);
          }}
        >
          <Typography>50 rows</Typography>
        </MenuItem>
      </Menu>

      <Toaster Toaster={toaster} setToaster={settoaster} />
    </>
  );
};

export default FacilityManage;
